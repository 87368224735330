import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.css'],
})
export class DoughnutChartComponent {
  chart: any;
  @ViewChild('dChart', { static: false }) dChart: ElementRef = {} as ElementRef;

  @Input() cutOut: number = 81;

  @Input() set OnPerChange(value: number) {
    this.answerPer = parseFloat(value.toFixed(0));
    this.chartLogic(this.answerPer);
  }

  private answerPer: number = 0;

  arrOne: any = {
    numbers: [0, 0, 0, 0, 0],
    labels: ['Very Bad', 'Bad', 'Good', 'Very Good', 'Great', ''],
    colors: ['#FEE9DA', '#FDD4B9', '#FDC097', '#FCA265', '#FA7A23', '#FAFAFA'],
  };
  doughnutCharts: any = [this.arrOne];
  private chartLogic(perquestionVerenatge: number) {
    if (perquestionVerenatge >= 0 && perquestionVerenatge <= 20) {
      this.arrOne.numbers = [
        perquestionVerenatge,
        0,
        0,
        0,
        0,
        100 - perquestionVerenatge,
      ];
    } else if (perquestionVerenatge >= 21 && perquestionVerenatge <= 40) {
      this.arrOne.numbers = [
        0,
        perquestionVerenatge,
        0,
        0,
        0,
        100 - perquestionVerenatge,
      ];
    } else if (perquestionVerenatge >= 41 && perquestionVerenatge <= 60) {
      this.arrOne.numbers = [
        0,
        0,
        perquestionVerenatge,
        0,
        0,
        100 - perquestionVerenatge,
      ];
    } else if (perquestionVerenatge >= 61 && perquestionVerenatge <= 80) {
      this.arrOne.numbers = [
        0,
        0,
        0,
        perquestionVerenatge,
        0,
        100 - perquestionVerenatge,
      ];
    } else if (perquestionVerenatge >= 81 && perquestionVerenatge <= 100) {
      this.arrOne.numbers = [
        0,
        0,
        0,
        0,
        perquestionVerenatge,
        100 - perquestionVerenatge,
      ];
    } else {
      this.arrOne.numbers = [
        perquestionVerenatge,
        0,
        0,
        0,
        0,
        0,
        0
      ];
    }
    this.doughnutCharts = [this.arrOne];


    window.setTimeout(() => {
      this.generateChart();
    }, 100);
  }
  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.generateChart();
  }

  private generateChart() {

    if (!this.dChart) return;

    let cvs = this.dChart.nativeElement || undefined;

    if (!cvs) return;

    if (this.chart) this.chart.destroy();

    this.chart = new Chart(cvs, {
      type: 'doughnut',
      plugins: [
        {
          id: 'custom_canvas_background_color',
          beforeDraw: (chart, args, options) => {
            var height = this.chart.height;
            var fontSize = (height / 114).toFixed(2);
            this.chart.ctx.font = fontSize + 'em Verdana';
            this.chart.ctx.textBaseline = 'middle';

            var text = this.answerPer + '%';
            var textY = height / 2;
            this.chart.ctx.fillText(text, 70, textY);
          },
        },
      ],
      data: {
        labels: this.arrOne.labels,
        datasets: <any>[
          {
            data: this.arrOne.numbers,
            backgroundColor: this.arrOne.colors,
            fill: false,
            borderWidth: 0,
          },
        ],
      },
      options: <any>{
        plugins: {
          legend: {
            display: true,
            position: 'right',
            onClick: (e: any, i: { index: string | number }) => {
              // get index from LegendItem
              e.preventDefault();
            },
          },
        },
        aspectRatio: 1.8,
        responsive: false,
        cutoutPercentage: this.cutOut,
        tooltips: {
          enabled: true,
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          },
        },
      },
    });
  }
}
