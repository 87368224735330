import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { SubSink } from 'src/app/Core/SubSink';
import { debounce } from 'typescript-debounce-decorator';

@Directive({
  selector: '[numberDir]'
})
export class NumberDirective {
  ngOnDestroy() {
    this.subSink.unSubscribe();
  }

  private subSink = new SubSink();

  @Input() default: any = 0;

  @Input() doSetDefault: boolean = false;

  @Input() MaxNumber: number = Infinity;

  @Input() MinNumber: number = -Infinity;

  @Input() setDefaultMaxNumber: boolean = false;

  @Input() MaxLength: number = 14;

  constructor(private control: NgControl, private el: ElementRef) { }

  ngOnInit() {
    this.subSink.sink = this.control.control?.valueChanges.subscribe((event: any) => {
      this.onInputChange(event);
    });
  }

  // @HostListener('keydown.tab', ['$event']) onKeyPressAny(event: any) {
  //   this.setValue(event);
  // }

  // @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {

  // }

  @HostListener('keydown', ['$event'])
  @debounce(100)
  keydown(event: KeyboardEvent) {
    let value = this.el.nativeElement.value;

    if (value && value.length <= 0) {
      return;
    }

    if (value.length > this.MaxLength) {
      return;
    }

    if (value == 0) {
      if (this.control && this.control.control) {
        let control = this.control;
        setTimeout(() => {
          control?.control?.setValue(event.key);
        }, 1);

      }
    }
  }

  // @HostListener('keyup', ['$event']) keyup(event: KeyboardEvent) {
  //   this.setValue(event);
  // }

  // @HostListener('change', ['$event']) change(event: KeyboardEvent) {
  //   this.setValue(event);
  // }

  setValue(event: any) {
    let value = 0;

    if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(event.key)) {
      if (this.el.nativeElement.value) {
        value = this.convertToNumber(this.el.nativeElement.value);
      }

      if (this.checkValidations(value, event.key)) {
        return;
      }
    }

    if (!this.el.nativeElement.value || this.el.nativeElement.value == null || this.el.nativeElement.value == undefined) {
      if (this.doSetDefault) {
        this.el.nativeElement.value = this.default;
      } else {
        this.el.nativeElement.value = '';
      }
    }

    this.el.nativeElement.value = this.el?.nativeElement?.value?.replace(/[^0-9.]/g, '');

    if (this.control && this.control.control && this.control.control.value) {
      this.control.control.setValue(this.el.nativeElement.value);
    }
    return;

    // if (
    //   !this.el.nativeElement.value ||
    //   this.el.nativeElement.value == null ||
    //   this.el.nativeElement.value == undefined ||
    //   this.el.nativeElement.value == ''
    // ) {
    //   if (this.doSetDefault) {
    //     this.el.nativeElement.value = this.default;
    //   } else {
    //     this.el.nativeElement.value = '';
    //   }
    // } else {
    //   if (this.checkValidations(value, event.key)) {
    //     return;
    //   }
    // }
  }

  private checkValidations(value: number, key: string) {
    if (value > this.MaxNumber && this.setDefaultMaxNumber) {
      this.el.nativeElement.value = this.MaxNumber;

      if (this.control && this.control.control && this.control.control.value) {
        this.control.control.setValue(this.MaxNumber);
      }
    } else if (value < this.MinNumber) {
      this.el.nativeElement.value = this.MinNumber;

      if (this.control && this.control.control && this.control.control.value) {
        this.control.control.setValue(this.MinNumber);
      }
    } else if (value == 0) {
      if (this.control && this.control.control) {
        this.control.control.setValue(key);
      }
    } else if (value.toString().length > this.MaxLength) {
      value = this.convertToNumber(value.toString().substring(0, this.MaxLength));
      this.el.nativeElement.value = value;
      if (this.control && this.control.control && this.control.control.value) {
        this.control.control.setValue(value);
      }
    } else {
      return false;
    }
    return true;
  }

  private convertToNumber(value: any): number {
    try {
      let data = value.toString() as string;

      data = data?.replace(/,/g, '');
      let numRes = Number(data);

      if (isNaN(numRes)) {
        return 0;
      }

      return numRes;
    } catch (e) {
      return 0;
    }
  }

  @HostListener('keydown.tab', ['$event'])
  @HostListener('paste', ['$event'])
  // @HostListener('keydown', ['$event'])
  @HostListener('keyup', ['$event'])
  //@HostListener('change', ['$event'])
  onInputChange(event: KeyboardEvent | ClipboardEvent | Event) {
    setTimeout(() => {
      this.updateValue(event);
    }, 10);
  }

  private updateValue(event: KeyboardEvent | ClipboardEvent | Event) {
    const input = this.el.nativeElement as HTMLInputElement;
    let value = input?.value?.replace(/[^0-9.]|\.(?=.*\.)/g, ''); // only allow numbers and a single dot

    if (!value || value == '') {
      value = this?.control?.value?.replace(/[^0-9.]|\.(?=.*\.)/g, '');
    }

    // if(!value || value == ''){
    //   return;
    // }

    input.value = value;
    if (value?.[value?.length - 1] == '.') {
      return;
    }

    let parsed = parseFloat(value);

    if (this.MaxLength && value?.length > this.MaxLength) {
      value = value.slice(0, this.MaxLength);
      input.value = value;
      parsed = parseFloat(value);
    }

    if (this.doSetDefault && value === '') {
      value = this.default.toString();
      input.value = value;
    }

    if (this.setDefaultMaxNumber && !isNaN(parsed)) {
      value = Math.min(parsed, this.MaxNumber).toString();
      input.value = value;
    }

    if (!isNaN(parsed)) {
      if (parsed > this.MaxNumber) {
        value = this.MaxNumber.toString();
        input.value = value;
      } else if (parsed < this.MinNumber) {
        value = this.MinNumber.toString();
        input.value = value;
      }
    }

    if (value !== input?.value) {
      event?.preventDefault();
      input.dispatchEvent(new Event('input'));
    }

    if (this.control && this.control.control) {
      this.control.control.setValue(this.el.nativeElement.value, { emitEvent: false });
    }
  }
}
