<div class="assessment-topbar">
  <div class="mobile-header">
    <!-- <a class="mobile-menu" (click)="HeaderSidebarOpen()" href="javascript:void(0);"
      ><img src="/assets/images/menu-icon.svg" alt="menu"
    /></a> -->
    <a href="javascript:void(0);" class="me-3" (click)="dashboardModal(null)">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9 3.65625C9.46599 3.65625 9.84375 3.27849 9.84375 2.8125C9.84375 2.34651 9.46599 1.96875 9 1.96875C8.53401 1.96875 8.15625 2.34651 8.15625 2.8125C8.15625 3.27849 8.53401 3.65625 9 3.65625Z"
          stroke="#E5702D"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9 9.84375C9.46599 9.84375 9.84375 9.46599 9.84375 9C9.84375 8.53401 9.46599 8.15625 9 8.15625C8.53401 8.15625 8.15625 8.53401 8.15625 9C8.15625 9.46599 8.53401 9.84375 9 9.84375Z"
          stroke="#E5702D"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9 16.0312C9.46599 16.0312 9.84375 15.6535 9.84375 15.1875C9.84375 14.7215 9.46599 14.3438 9 14.3438C8.53401 14.3438 8.15625 14.7215 8.15625 15.1875C8.15625 15.6535 8.53401 16.0312 9 16.0312Z"
          stroke="#E5702D"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </a>
    <a class="mobile-logo">
      <img [src]="logoPath" alt="logo" (click)="homePageRedirection()" />
    </a>
  </div>
  <div class="userinfo flex-wrap align-items-center justify-content-between d-flex">
    <div class="flex-wrap align-items-center d-flex header-dashboard">
      <a href="javascript:void(0);" class="header-logo" [routerLink]="['/pages/dashboard']">
        <img src="/assets/images/agent-logo-side-new.svg" />
      </a>
      <a href="javascript:void(0);" class="me-3" (click)="dashboardModal(null)">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9 3.65625C9.46599 3.65625 9.84375 3.27849 9.84375 2.8125C9.84375 2.34651 9.46599 1.96875 9 1.96875C8.53401 1.96875 8.15625 2.34651 8.15625 2.8125C8.15625 3.27849 8.53401 3.65625 9 3.65625Z"
            stroke="#E5702D"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9 9.84375C9.46599 9.84375 9.84375 9.46599 9.84375 9C9.84375 8.53401 9.46599 8.15625 9 8.15625C8.53401 8.15625 8.15625 8.53401 8.15625 9C8.15625 9.46599 8.53401 9.84375 9 9.84375Z"
            stroke="#E5702D"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9 16.0312C9.46599 16.0312 9.84375 15.6535 9.84375 15.1875C9.84375 14.7215 9.46599 14.3438 9 14.3438C8.53401 14.3438 8.15625 14.7215 8.15625 15.1875C8.15625 15.6535 8.53401 16.0312 9 16.0312Z"
            stroke="#E5702D"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </a>
      <a href="javascript:void(0);" [routerLink]="['/pages/dashboard']"> Dashboard </a>
    </div>
    <div>
      <img [src]="remoteImageUrl + userData.userImage" onerror="this.onerror=null;this.src='/assets/images/default.jpg';" />
      <div ngbDropdown class="d-inline-block" [autoClose]="autoClose">
        <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
          <span>{{ userData.name }}</span>
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <button ngbDropdownItem (click)="userHeaderClick('profile')">Profile</button>
          <button ngbDropdownItem (click)="userHeaderClick('password')">Change password</button>
          <button (click)="userHeaderClick('logout')" ngbDropdownItem>Logout</button>
        </div>
      </div>
    </div>
  </div>
</div>
