import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appString]'
})
export class StringDirective {
  @Input('stringLength') maxLength: number = 9999999999;
  @Input() minLength: number = 0;

  constructor(private el: ElementRef) { }

  private checkLength(value: string) {
    if (value.length < this.minLength) {
      this.el.nativeElement.setCustomValidity(`Minimum length is ${this.minLength}`);
    } else if (value.length > this.maxLength) {
      this.el.nativeElement.setCustomValidity(`Maximum length is ${this.maxLength}`);
    } else {
      this.el.nativeElement.setCustomValidity('');
    }
  }

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    const input = this.el.nativeElement as HTMLInputElement;

    if(!input) return;

    const maxLength = this.maxLength;
    const pastedText = event.clipboardData?.getData('text');
    const value = input.value;
    const selectionStart = input.selectionStart || 0;
    const selectionEnd = input.selectionEnd || 0;

    setTimeout(() => {
      const pastedValue = pastedText?.substring(0, maxLength - value.length + selectionEnd - selectionStart);
      const newValue = value.substring(0, selectionStart) + pastedValue + value.substring(selectionEnd);
      input.value = newValue;
      this.checkLength(newValue);
    }, 0);
  }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    const input = this.el.nativeElement as HTMLInputElement;
    const value = input.value;
    const keyCode = event.which || event.keyCode;
    if (keyCode === 8 || keyCode === 46 || keyCode === 37 || keyCode === 39) {
      return;
    }
    if (value.length >= this.maxLength) {
      event.preventDefault();
    }
  }

  @HostListener('input', ['$event']) onInput(event: Event) {
    const input = this.el.nativeElement as HTMLInputElement;
    const value = input.value;
    this.checkLength(value);
    if (value.length > this.maxLength) {
      input.value = value.substr(0, this.maxLength);
      this.checkLength(input.value);
    }
  }

  @HostListener('blur', ['$event']) onBlur(event: Event) {
    const input = this.el.nativeElement as HTMLInputElement;
    const value = input.value;
    this.checkLength(value);
  }
}




