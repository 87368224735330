import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { appRoute } from 'src/app/Core/routes.enum';
import { Storage } from 'src/app/Core/storage';
import { UserRoleEnum } from 'src/app/Core/userRole.enum';
import { IUser } from 'src/app/Models/user.interface';
import { DashboardPopupComponent } from 'src/app/pages/dashboard/dashboard-popup/dashboard-popup.component';
import { SideBarService } from 'src/app/shared/Services/sideBar.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css']
})
export class SideBarComponent implements OnInit {
  sideBarList: any[] = [];
  public userData: IUser = {} as IUser;
  logoPath = '/assets/images/navigation-logo.svg';

  constructor(private sideBarService: SideBarService, private router: Router, public modalService: NgbModal) {
    if (environment.isAgent) {
      this.logoPath = '/assets/images/agent-white-logo-new.svg';
    }
  }

  async ngOnInit() {
    this.userData = (await Storage.getUserData()) ?? ({} as IUser);
    if (this.userData.role === UserRoleEnum.ProfitAdviser) {
      this.sideBarList.push(
        {
          title: 'Assessment',
          link: '/' + appRoute.pages + '/' + appRoute.assessment,
          imgPath: '/assets/images/assessment-icon.svg'
        },
        // {
        //   title: 'Inquiry',
        //   link: '/' + appRoute.pages + '/' + appRoute.inquiry,
        //   imgPath: '/assets/images/question-answer-fill.svg'
        // },
        {
          title: 'Agent Profit Plan Classes',
          link: '/' + appRoute.pages + '/' + appRoute.agentProfitPlan,
          imgPath: '/assets/images/agentprofitplan.svg'
        },
        {
          title: 'Scripts and Canva Templates',
          link: '/' + appRoute.pages + '/' + appRoute.agentProfitScript,
          imgPath: '/assets/images/phone-fill (1).svg'
        },
        {
          title: 'How to Use the Agent Profit Plan',
          link: '/' + appRoute.pages + '/' + appRoute.agentProfitUses,
          imgPath: '/assets/images/question-fill.svg'
        },
        {
          title: 'Weekly Plan Success',
          link: '/' + appRoute.pages + '/' + appRoute.weeklyPlans,
          imgPath: '/assets/images/calendar-fill.svg'
        },
        {
          title: 'Guidance',
          link: '/' + appRoute.pages + '/' + appRoute.guidance,
          imgPath: '/assets/images/raisehand (1).svg'
        },
        {
          title: 'Previous Coaching Calls',
          link: '/' + appRoute.pages + '/' + appRoute.previousCoachingCalls,
          imgPath: '/assets/images/Frame.svg'
        },
        {
          title: 'Magazine',
          link: '/' + appRoute.pages + '/' + appRoute.magazine,
          imgPath: '/assets/images/magazine.svg'
        },
        {
          title: 'Commercial Loans',
          link: '/' + appRoute.pages + '/' + appRoute.commercialLoans,
          imgPath: '/assets/images/commercial-loan.svg'
        },
        {
          title: 'Chat GPT',
          link: '/' + appRoute.pages + '/' + appRoute.chatGpt,
          imgPath: '/assets/images/chatgpt.svg'
        },
        {
          title: 'Publish Your Book',
          link: '/' + appRoute.pages + '/' + appRoute.publishYourBook,
          imgPath: '/assets/images/publishYourBook.svg'
        },
        {
          title: 'Message Board',
          link: '/' + appRoute.pages + '/' + appRoute.messageboard,
          imgPath: '/assets/images/chat 1.svg'
        },
        {
          title: 'Social Media Post',
          link: '/' + appRoute.pages + '/' + appRoute.socialMediaPost,
          imgPath: '/assets/images/socialMediaPost.svg'
        }
      );
    } else if (this.userData.role == UserRoleEnum.Admin) {
      if (!environment.isAgent) {
        this.sideBarList.push(
          {
            title: 'Assessment',
            link: '/' + appRoute.pages + '/' + appRoute.assessment,
            imgPath: '/assets/images/assessment-icon.svg'
          },
          {
            title: 'Question Config',
            link: '/' + appRoute.pages + '/' + appRoute.questionConfig,
            imgPath: '/assets/images/menu-que-icon.svg'
          }
        );
      } else {
        this.sideBarList.push(
          {
            title: 'Profit Advisor',
            link: '/' + appRoute.pages + '/' + appRoute.profitofficer,
            imgPath: '/assets/images/officer.svg'
          },
          // {
          //   title: 'Inquiry',
          //   link: '/' + appRoute.pages + '/' + appRoute.inquiry,
          //   imgPath: '/assets/images/question-answer-fill.svg'
          // },
          // {
          //   title: 'PO Inquiry',
          //   link: '/' + appRoute.pages + '/' + appRoute.poInquiry,
          //   imgPath: '/assets/images/assessment-icon.svg'
          // },
          {
            title: 'Question Config',
            link: '/' + appRoute.pages + '/' + appRoute.questionConfig,
            imgPath: '/assets/images/menu-que-icon.svg'
          },
          {
            title: 'Agents',
            link: '/' + appRoute.pages + '/' + appRoute.agents,
            imgPath: '/assets/images/officer.svg'
          },
          // {
          //   title: 'FAQ',
          //   link: '/' + appRoute.pages + '/' + appRoute.faq,
          //   imgPath: '/assets/images/faq.svg'
          // },
          {
            title: 'Archive',
            link: '/' + appRoute.pages + '/' + appRoute.unarchive,
            imgPath: '/assets/images/archive.svg'
          },
          {
            title: 'Agent Profit Plan Classes',
            link: '/' + appRoute.pages + '/' + appRoute.agentProfitPlan,
            imgPath: '/assets/images/agentprofitplan.svg'
          },
          {
            title: 'Scripts and Canva Templates',
            link: '/' + appRoute.pages + '/' + appRoute.agentProfitScript,
            imgPath: '/assets/images/phone-fill (1).svg'
          },
          {
            title: 'How to Use the Agent Profit Plan',
            link: '/' + appRoute.pages + '/' + appRoute.agentProfitUses,
            imgPath: '/assets/images/question-fill.svg'
          },
          {
            title: 'Weekly Plan Success',
            link: '/' + appRoute.pages + '/' + appRoute.weeklyPlans,
            imgPath: '/assets/images/calendar-fill.svg'
          },
          {
            title: 'Guidance',
            link: '/' + appRoute.pages + '/' + appRoute.guidance,
            imgPath: '/assets/images/raisehand (1).svg'
          },
          {
            title: 'Previous Coaching Calls',
            link: '/' + appRoute.pages + '/' + appRoute.previousCoachingCalls,
            imgPath: '/assets/images/Frame.svg'
          },
          {
            title: 'Magazine',
            link: '/' + appRoute.pages + '/' + appRoute.magazine,
            imgPath: '/assets/images/magazine.svg'
          },
          {
            title: 'Commercial Loans',
            link: '/' + appRoute.pages + '/' + appRoute.commercialLoans,
            imgPath: '/assets/images/commercial-loan.svg'
          },
          {
            title: 'Chat GPT',
            link: '/' + appRoute.pages + '/' + appRoute.chatGpt,
            imgPath: '/assets/images/chatgpt.svg'
          },
          {
            title: 'Publish Your Book',
            link: '/' + appRoute.pages + '/' + appRoute.publishYourBook,
            imgPath: '/assets/images/publishYourBook.svg'
          },
          {
            title: 'Message board',
            link: '/' + appRoute.pages + '/' + appRoute.messageboard,
            imgPath: '/assets/images/chat 1.svg'
          },
          {
            title: 'Social Media Post',
            link: '/' + appRoute.pages + '/' + appRoute.socialMediaPost,
            imgPath: '/assets/images/socialMediaPost.svg'
          }
        );
      }
    } else {
      this.sideBarList.push({
        title: 'Assessment',
        link: '/' + appRoute.pages + '/' + appRoute.assessment,
        imgPath: '/assets/images/assessment-icon.svg'
      });
    }
  }
  onLogoClick() {
    if (environment.isAgent) {
      this.router.navigateByUrl(appRoute.pages + '/' + appRoute.dashboard);
    } else {
      this.router.navigateByUrl(appRoute.pages + '/' + appRoute.assessment);
    }
  }
  HeaderSidebarClose() {
    this.sideBarService.close();
  }

  dashboardModal(data: any) {
    const modalRef = DashboardPopupComponent.open(this.modalService);
    modalRef.result
      .then((data: any) => {
        if (data) {
          // this.getAllData();
        }
      })
      .catch((res) => { });
  }

  trackById(index: number, item: any): number {
    return item.id;
  }
}
