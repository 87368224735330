<div class="modal-content">
    <div class="modal-body">
        <button type="button" (click)="close(false)" class="close btn" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
        <p>{{ config.message }}</p>
    </div>
    <div class="modal-footer justify-content-end">
        <button type="button" (click)="close(true)" class="btn btn-primary" ng-click="deleteRecord()">OK</button>
    </div>
</div>