import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.css']
})
export class MessageDialogComponent implements OnInit {

  constructor() { }

  private modelRef: NgbModalRef = {} as NgbModalRef;

  config = {
    message: ""
  };

  ngOnInit(): void {

  }

  public static open(modalService: NgbModal, message: string) {
    let ref = modalService.open(MessageDialogComponent);

    let component = ref.componentInstance as MessageDialogComponent;

    component.modelRef = ref;
    component.config.message = message;

    return ref;
  }

  close(check: boolean) {
    this.modelRef.close(check);
  }

}
