<section>
  <div class="sign-up-wrapper d-flex flex-wrap align-items-center">
    <div class="sign-up-left w-50 text-center height-sidebar-100">
      <img [src]="logoPath" alt="logo" />
      <img src="/assets/images/signup-bottom.svg" alt="img" class="bottom-img" />
    </div>
    <div class="sign-up-right w-50">
      <div class="sign-up-right-wrapper">
        <div class="mobile-logo text-center">
          <img [src]="logoPath" alt="logo" />
        </div>
        <h1 class="main-heading text-center">Login</h1>
        <form [formGroup]="form">
          <!-- <div class="form-control-main position-relative">    
                        <span>
                            <img src="/assets/images/user-icon.svg" />
                        </span>
                        <input type="text" class="form-control" placeholder="Enter user name">
                    </div> -->
          <div class="form-control-main position-relative">
            <span>
              <img src="/assets/images/user-email.svg" alt="Email" />
            </span>
            <input formControlName="email" type="email" class="form-control" placeholder="Enter email" />
            <div class="invalid-feedback"
              *ngIf="form.controls['email'].invalid && (form.controls['email'].dirty || form.controls['email'].touched)">
              <div *ngIf="form.controls['email'].errors?.required">Please enter email.</div>
              <div *ngIf="form.controls['email'].errors?.email">Please enter valid email.</div>
            </div>
          </div>

          <div class="form-control-main position-relative">
            <span>
              <img src="/assets/images/eye-fill.svg" alt="View" />
            </span>
            <input formControlName="password" type="password" class="form-control" placeholder="Password"
              autocomplete="off" />
            <div class="invalid-feedback"
              *ngIf="form.controls['password'].invalid && (form.controls['password'].dirty || form.controls['password'].touched)">
              <div *ngIf="form.controls['password'].errors?.required">Please enter password.</div>
            </div>
          </div>

          <div class="form-control-main text-right">
            <a class="forget-password" (click)="onForgotUpClick()" href="javascript:void(0);">Forgot Password?</a>
          </div>
          <div class="form-control-main sign-in-btn text-center">
            <button (click)="onSubmit()">Login</button>
          </div>
        </form>
        <p class="text-center" *ngIf="!isAgent">
          Doesn't have an account?
          <a (click)="onSignUpClick()" href="javascript:void(0);">Sign Up Here</a>
        </p>

        <p class="text-center" *ngIf="isAgent">
          Are You a Real Estate Agent Who Would Like to Learn How to Grow Your Business? If So
          <a (click)="onInquiryClick()" href="javascript:void(0);">Click Here</a>
          <br />or
          <a (click)="onSignUpClick()" href="javascript:void(0);">Sign Up Here</a>
        </p>
      </div>
    </div>
  </div>
</section>