import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoaderComponent } from '../loader/loader.component';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private modelRef: LoaderComponent | null = null;

  constructor(private readonly modalService: NgbModal) {}

  open() {
    this.close();

    this.modelRef = LoaderComponent.open(this.modalService);
  }

  close() {
    if (this.modelRef) {
      this.modelRef.close();

      this.modelRef = null;
    }
  }
}
