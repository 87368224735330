import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[defaultNumber]'
})
export class DefaultNumberDirective {
  regexStr = '^[a-zA-Z0-9_]*$';
  regexNum: RegExp = new RegExp('^[0-9]*$');

  @Input() default: Number = 0;

  constructor(private el: ElementRef) {}

  @HostListener('keydown.tab', ['$event']) onKeyPressAny(event: any) {
    this.setDefaultValue(event);
  }

  @HostListener('keypress', ['$event']) onKeyPress(event: any) {
    this.setDefaultValue(event);
  }

  @HostListener('keydown', ['$event']) onKeyDown(event: any) {
    this.setDefaultValue(event);
  }

  @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
    this.setDefaultValue(event);
  }

  setDefaultValue(event: any) {
    if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(event.key)) {
      return;
    }

    if (
      !this.el.nativeElement.value ||
      this.el.nativeElement.value == null ||
      this.el.nativeElement.value == undefined ||
      this.el.nativeElement.value == ''
    ) {
      const initialValue: string = this.el.nativeElement.value.concat(event.key);
      // this.el.nativeElement.value = initialValue.replace(/[^0-9.]|\.(?=.*\.)/g, '');
      if (initialValue && !String(initialValue).match(this.regexNum)) {
        event.stopPropagation();
      }
    }
  }
}
