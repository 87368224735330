import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {CountryISO, PhoneNumberFormat, SearchCountryField} from 'ngx-intl-tel-input';
import {accountRoutes} from 'src/app/Core/routes.enum';
import {SubSink} from 'src/app/Core/SubSink';
import {IFAQ} from 'src/app/Models/faq.interface';
import {IUser} from 'src/app/Models/user.interface';
import {AccountService} from 'src/app/services/account.service';
import {AddressServiceService} from 'src/app/services/address.service';
import {FAQService} from 'src/app/services/faq.service';
import {InquiryService} from 'src/app/services/inquiry.service';
import {ToastService} from 'src/app/services/toast.service';
import {LoaderService} from 'src/app/shared/Services/loader.service';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-agent-landing-page',
  templateUrl: './agent-landing-page.component.html',
  styleUrls: ['./agent-landing-page.component.css']
})
export class AgentLandingPageComponent implements OnInit {
  ngOnDestroy(): void {
    this.subSink.unSubscribe();
  }

  private subSink = new SubSink();

  @ViewChild('videoTemplate', {static: false}) videoTemplate: ElementRef<any> = {} as ElementRef<any>;
  @ViewChild('videoTemplate2', {static: false}) videoTemplate2: ElementRef<any> = {} as ElementRef<any>;

  baseImageUrl = environment.remoteImageUrl;

  countries: any;
  states: string[] = [];
  cities: string[] = [];

  country = new FormControl('United States', [Validators.required]);
  state = new FormControl('', Validators.required);
  city = new FormControl('', Validators.required);

  public parentUri: string = '';
  profitAdviser: IUser | null = null;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  PhoneNumberFormat = PhoneNumberFormat;
  logoPath = '/assets/images/signup-logo.svg';
  user: any = '';
  sideImage = '/assets/images/signin-bottom.svg';
  videoUrl = '';
  imageUrl = '/assets/images/signin-bottom.svg';
  form: any = '';
  allFAQ: IFAQ[] = [];
  currentYear: number;

  constructor(
    private toast: ToastService,
    private router: Router,
    private loader: LoaderService,
    private inquiry: InquiryService,
    private address: AddressServiceService,
    routeSnapShot: ActivatedRoute,
    private account: AccountService,
    private faqService: FAQService
  ) {
    this.countries = this.address.getCountries();
    let snapShot = routeSnapShot.snapshot;

    this.parentUri = snapShot.paramMap.get('uri') ?? '';

    this.checkParentUri();

    this.currentYear = new Date().getFullYear();
  }

  ngOnInit(): void {
    this.setForm();

    this.checkCountryAndStateChanges();

    this.getAllFAQ();
  }

  private setForm() {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.min(3), Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      mobileNo: new FormControl(undefined, [Validators.required]),
      address: new FormControl('', [Validators.required]),
      pinCode: new FormControl('', [Validators.required]),
      country: this.country,
      contentUrl: new FormControl('', []),
      state: this.state,
      city: this.city
    });
  }

  private checkCountryAndStateChanges() {
    this.states = this.address.getStatesByCountry('US');
    this.country.disable();
  }

  private getAllFAQ() {
    this.subSink.sink = this.faqService.getAll().subscribe({
      next: (faq: IFAQ[]) => {
        this.allFAQ = faq;
      },
      error: (err: any) => {}
    });
  }

  onLoginClick() {
    this.router.navigateByUrl(accountRoutes.account + '/' + accountRoutes.login);
    return false;
  }

  onSignUpClick() {
    this.router.navigateByUrl(this.parentUri + '/signup');
    return false;
  }

  private checkParentUri() {
    if (this.parentUri == null || this.parentUri == '') {
      this.toast.error({
        title: '',
        message: 'Link Expired',
        timeout: 2000
      });

      this.router.navigateByUrl(accountRoutes.account + '/' + accountRoutes.login);
      return;
    }

    this.loader.open();

    this.account.checkIfUriCorrect(this.parentUri).subscribe({
      next: (value) => {
        this.loader.close();
        if (!value) {
          this.toast.error({
            title: '',
            message: 'Link Expired',
            timeout: 2000
          });

          this.router.navigateByUrl(accountRoutes.account + '/' + accountRoutes.login);
        } else {
          this.profitAdviser = value;
          if (this.profitAdviser?.signUpVideoUrl && this.profitAdviser?.signUpVideoUrl != '') {
            this.videoUrl = this.profitAdviser.signUpVideoUrl;
            var elm = document.createElement('iframe') as HTMLIFrameElement;
            elm.src = this.videoUrl;
            elm.width = '565';
            elm.height = '315';
            elm.title = 'YouTube video player';
            elm.frameBorder = '0';
            elm.allow = 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture';
            elm.allowFullscreen = true;
            // elm.style.paddingTop = '20px';

            var elm2 = document.createElement('iframe') as HTMLIFrameElement;
            elm2.src = this.videoUrl;
            elm2.width = '100%';
            elm2.height = '315';
            elm2.title = 'YouTube video player';
            elm2.frameBorder = '0';
            elm2.allow = 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture';
            elm2.allowFullscreen = true;
            // elm2.style.paddingTop = '20px';

            this.videoTemplate.nativeElement.appendChild(elm);
            this.videoTemplate2.nativeElement.appendChild(elm2);
          } else if (this.profitAdviser?.signUpImageUrl) {
            this.imageUrl = environment.remoteImageUrl + this.profitAdviser.signUpImageUrl;
          } else {
            this.imageUrl = '';
            this.videoUrl = '';
          }
        }
      },
      error: (err) => {
        this.loader.close();
        this.toast.error({
          title: '',
          message: 'Link Expired',
          timeout: 2000
        });

        this.router.navigateByUrl(accountRoutes.account + '/' + accountRoutes.login);
      }
    });
  }

  onSubmitInquiry() {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    let req = {...this.form.value};
    req.state = req.state || '';
    req.city = req.city || '';
    req.country = 'United States';
    req.mobileNo = req?.mobileNo!['internationalNumber'];

    if (this.profitAdviser) {
      req.ProfitAdvisorId = this.profitAdviser.id;
    }

    this.loader.open();

    this.subSink.sink = this.inquiry.add(req as any).subscribe({
      next: (data: any) => {
        this.loader.close();
        if (data) {
          this.toast.success({
            message: 'Inquiry Sent',
            title: '',
            timeout: 2000
          });

          this.form.patchValue({
            name: '',
            email: '',
            mobileNo: '',
            address: '',
            pinCode: '',
            contentUrl: '',
            state: '',
            city: ''
          });

          this.form.markAsPristine();
          this.form.markAsUntouched();

          this.setForm();
        } else {
          this.toast.error({
            title: '',
            message: 'Something went wrong',
            timeout: 2000
          });
        }
      },
      error: (error: any) => {
        this.loader.close();
        this.toast.error({
          title: '',
          message: error?.error?.errorMessage ?? 'Something went wrong',
          timeout: 2000
        });
      },
      complete: () => {
        this.loader.close();
      }
    });
  }

  onHomeClick() {
    var contactNowElm = window.document.getElementById('home');

    if (contactNowElm) {
      contactNowElm.scrollIntoView({block: 'end', inline: 'end'});
    }
  }

  onContactNowClick() {
    var contactNowElm = window.document.getElementById('contact-now');

    if (contactNowElm) {
      contactNowElm.scrollIntoView({block: 'end', inline: 'end'});
    }
  }

  onAboutUsClick() {
    var contactNowElm = window.document.getElementById('aboutUsSection');

    if (contactNowElm) {
      contactNowElm.scrollIntoView({block: 'end', inline: 'end'});
    }
  }

  onWhyUsClick() {
    var contactNowElm = window.document.getElementById('whyUsSection');

    if (contactNowElm) {
      contactNowElm.scrollIntoView({block: 'end', inline: 'end'});
    }
  }

  isMenuOpen: boolean = false;
  onMenuClick() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  allQuestions: any = [
    {
      id: 'd8a6faa2-c482-462f-a400-08daf84f36ec',
      companyId: '00000000-0000-0000-0000-000000000000',
      name: 'erfwefhgjkfgvdfjhf',
      displayOrder: 1,
      questions: [
        {
          id: 'b8c843b8-e1c0-4257-9184-08daf87cf261',
          assessmentQuestionCategoryId: 'd8a6faa2-c482-462f-a400-08daf84f36ec',
          companyId: '00000000-0000-0000-0000-000000000000',
          question: 'Que 1',
          questionHint: 'fhkjh',
          percentage: 56,
          unit: 67,
          isActive: false
        }
      ]
    },
    {
      id: 'cec04913-e322-4308-a3ff-08daf84f36ec',
      companyId: '00000000-0000-0000-0000-000000000000',
      name: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
      displayOrder: 2,
      questions: [
        {
          id: '5befebef-d457-4d31-ee6a-08daf854ae68',
          assessmentQuestionCategoryId: 'cec04913-e322-4308-a3ff-08daf84f36ec',
          companyId: '00000000-0000-0000-0000-000000000000',
          question: 'bfbgf',
          questionHint: 'gfb',
          percentage: 21,
          unit: 13,
          isActive: false
        },
        {
          id: 'c9a69195-1b39-4b9b-8850-08db0376a3a1',
          assessmentQuestionCategoryId: 'cec04913-e322-4308-a3ff-08daf84f36ec',
          companyId: '00000000-0000-0000-0000-000000000000',
          question: 'fgggfgf',
          questionHint: 'hjgg',
          percentage: 54,
          unit: 5,
          isActive: false
        }
      ]
    }
  ];

  onSectionChange(id: number) {
    for (var i = 0; i < this.allFAQ.length; i++) {
      let question = this.allFAQ[i];
      if (question.id == id) {
        question.active = !question.active;
      } else {
        question.active = false;
      }
    }
  }
}
