import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModalModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { LoaderService } from 'src/app/shared/Services/loader.service';
import { SideBarService } from 'src/app/shared/Services/sideBar.service';
import { BarChartComponent } from 'src/app/shared/bar-chart/bar-chart.component';
import { CommonPdfDownloadComponent } from 'src/app/shared/common-pdf-download/common-pdf-download.component';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { AlphaNumericDirective } from 'src/app/shared/directives/alpha-numeric.directive';
import { DefaultNumberDirective } from 'src/app/shared/directives/default-number.directive';
import { DoughnutChartComponent } from 'src/app/shared/doughnut-chart/doughnut-chart.component';
import { LoaderComponent } from 'src/app/shared/loader/loader.component';
import { MessageDialogComponent } from 'src/app/shared/message-dialog/message-dialog.component';
import { ToastService } from '../services/toast.service';
import { NoDotDirective } from './directives/NoDot.directive';
import { DigitonlyDirective } from './directives/digitonly.directive';
import { NumberDirective } from './directives/numberDirective.directive';
import { OpenLinkDirective } from './directives/open-link.directive';
import { StringDirective } from './directives/string.directive';
import { TrimInputDirective } from './directives/trim-input.directive';
import { GaugeChartComponent } from './gauge-chart/gauge-chart.component';
import { ReversePipe } from './pipes/reverse.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { SafeHtmlPipe } from './pipes/safeHtml.pipe';
import { VideoPlayerComponent } from './video-player/video-player.component';

@NgModule({
  declarations: [
    LoaderComponent,
    ConfirmDialogComponent,
    MessageDialogComponent,
    BarChartComponent,
    DoughnutChartComponent,
    CommonPdfDownloadComponent,
    DefaultNumberDirective,
    AlphaNumericDirective,
    NumberDirective,
    GaugeChartComponent,
    StringDirective,
    NoDotDirective,
    // OtpInputComponent,
    DigitonlyDirective,
    TrimInputDirective,
    VideoPlayerComponent,
    SafePipe,
    SafeHtmlPipe,
    OpenLinkDirective,
    ReversePipe
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NgbModalModule, NgbTooltipModule, HttpClientModule],
  exports: [
    LoaderComponent,
    ConfirmDialogComponent,
    MessageDialogComponent,
    CommonPdfDownloadComponent,
    BarChartComponent,
    DoughnutChartComponent,
    DefaultNumberDirective,
    AlphaNumericDirective,
    NumberDirective,
    GaugeChartComponent,
    StringDirective,
    DigitonlyDirective,
    NgbTooltipModule,
    TrimInputDirective,
    NoDotDirective,
    VideoPlayerComponent,
    OpenLinkDirective,
    SafePipe,
    SafeHtmlPipe,
    ReversePipe
  ],
  providers: [
    {
      provide: LoaderService,
      useClass: LoaderService
    },
    {
      provide: ToastService,
      useClass: ToastService
    },
    {
      provide: SideBarService,
      useClass: SideBarService
    },
  ]
})
export class SharedModule { }
