import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {accountRoutes} from 'src/app/Core/routes.enum';
import { Storage } from 'src/app/Core/storage';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate {

  /**
   *
   */
  constructor(private router: Router) {


  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let user = Storage.getUserData();
    if (user == null) {
      this.router.navigateByUrl(accountRoutes.account + "/" + accountRoutes.login);
      return false;
    }

    let token = Storage.getToken();

    if (!token || token == null || token.length == 0) {
      this.router.navigateByUrl(accountRoutes.account + "/" + accountRoutes.login);
      return false;
    }
    let roles = route.data.roles as Array<string>;
    if (roles && route.data.roles[0] !== user.role) {
      this.router.navigateByUrl('/pages/assessment');
      return false;
    }

    return true;
  }


}


