<div class="chart-container" #canvasContainer></div>
<p class="percent text-center text-danger">
  {{ gaugeChartData.gaugeFirstPart | number }}
</p>
<div class="d-flex newClass">
  <p class="text-center p-3 pb-0 pt-0 startValue">
    {{ gaugeChartData.gaugeStartValue }}
  </p>
  <p class="text-center p-3 pb-0 pt-0 mlset">
    {{ gaugeChartData.gaugeEndValue | number : '1.0-0' }}
  </p>
</div>


