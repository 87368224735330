import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SubSink } from 'src/app/Core/SubSink';
import { ToastService } from 'src/app/services/toast.service';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../Services/loader.service';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.css']
})
export class VideoPlayerComponent implements OnInit, AfterViewInit {
  courseImages = environment.courseimages;
  private modelRef: NgbModalRef = {} as NgbModalRef;
  @ViewChild('videoTemplate', { static: false }) videoTemplate: ElementRef<any> = {} as ElementRef<any>;

  @ViewChild('playerContainer') playerContainer: ElementRef = {} as ElementRef<any>;
  // url = 'https://player.vimeo.com/video/76979871';
  isInvalidFormat = false
  videoPath: any = {};
  private subSink = new SubSink();
  ProfitPlanImageUrl = environment.ProfitPlanImageUrl;

  constructor(
    public modalService: NgbModal,
    private loader: LoaderService,
    private readonly toast: ToastService,

  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.renderVideo();
    // let path : any = this.courseImages + this.videoPath;
    // console.log("path", path);

    // // this.playerContainer.nativeElement.src = this.content.url;
    // const player = new Player(this.playerContainer.nativeElement, {
    // title: this.videoPath,
    // url: ' ',
    // width: 560,
    // height: 480,
    // portrait: true,
    // // id: path      
    // });
    // player.play().then(() => {
    //   console.log('what is up');
    // });
  }

  public static open(modalService: NgbModal, data: any) {
    var ref = modalService.open(VideoPlayerComponent);
    ref.componentInstance.modelRef = ref;
    ref.componentInstance.videoPath = data;

    // if (course) ref.componentInstance.courseEdit(course);
    return ref;
  }

  close() {
    this.modelRef?.close();
  }

  private renderVideo() {
    let path: any = '';
    if (this.videoPath) {
      path = this.videoPath.slice(this.videoPath.indexOf('.') + 1)

      if (path !== "mp4" && path !== 'webm') {
        this.isInvalidFormat = true;
      }
      else {

        if (!this.videoPath || this.videoPath == '') return;

        var elm = document.createElement('iframe') as HTMLIFrameElement;
        elm.src = this.courseImages + this.videoPath;
        elm.width = '100%';
        elm.height = '315';
        elm.title = 'YouTube video player';
        elm.frameBorder = '0';
        elm.allow = 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture';
        elm.allowFullscreen = true;
        elm.style.paddingTop = '20px';

        this.videoTemplate.nativeElement.appendChild(elm);
      }
    }

  }

  onDownloadClick() {
    this.loader.open();
    if (this.videoPath) {
      const url = this.ProfitPlanImageUrl + this.videoPath;
      fetch(url)
        .then((res) => res.blob())
        .then((file) => {
          let tempUrl = URL.createObjectURL(file);
          const aTag = document.createElement('a');
          aTag.href = tempUrl;
          aTag.download = url.replace(/^.*[\\\/]/, '');
          document.body.appendChild(aTag);
          aTag.click();
          URL.revokeObjectURL(tempUrl);
          aTag.remove();
          this.loader.close();
        })
        .catch(() => {
          this.loader.close();
          this.toast.error({
            title: '',
            message: 'Failed to download file!',
            timeout: 2000
          });
        });
    } else {
      this.loader.close();
      this.toast.error({
        title: '',
        message: 'No files available for this plan!',
        timeout: 2000
      });
    }
  }


  ngOnDestroy(): void {
    this.subSink.unSubscribe();
  }

}
