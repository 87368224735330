import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import {environment} from 'src/environments/environment';
import { apiRoutes } from '../Core/apiRoutes.enum';
import { Inquiry } from '../Models/Inquiry';
import { IUser } from '../Models/user.interface';

@Injectable({
  providedIn: 'root'
})
export class InquiryService {
  private baseApiUrl = environment.baseApiUrl;

  constructor(private readonly http: HttpClient) {}


  public GetAll(profitAdviserId:string| null,search:string){
    let url = this.baseApiUrl + apiRoutes.getAllInquire+"?search="+search;

    if(profitAdviserId){
      url += "&ProfitAdvisorId="+profitAdviserId;
    }

    return this.http.get(url);
  }

  public add(inquiry:Inquiry){
    return this.http.post(this.baseApiUrl + apiRoutes.addInquiry,inquiry);
  }

  public getByEmail(email:string): Observable<Inquiry> {
    let url = this.baseApiUrl + apiRoutes.GetByEmailId+"?email="+email;
    
    return this.http.get<Inquiry>(url);
  }

  
  public assignInquiry(inquiryChange:any): Observable<number> {
    let url = this.baseApiUrl + apiRoutes.addInquiry+"/Assign";
    
    return this.http.put<number>(url, inquiryChange);
  }

  public getProfitAdviserInquiry(search: string) {
    return this.http.get<IUser[]>(
      `${this.baseApiUrl + apiRoutes.User + '/profit/inquiry?search=' + search ?? ""}`
    );
  }

  public verifyAdviser(id:string){
    return this.http.post<IUser>(
      `${this.baseApiUrl + apiRoutes.User + '/verify/' + id ?? ""}`
      ,{}
    );
  }


}
