import { Component } from '@angular/core';
import {Storage} from './Core/storage';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  constructor() {
    var user = Storage.getUserData();

    if (user) Storage.setUserData(user);
  }

}
