<div class="chart-container" #canvasContainer></div>
<p class="percent text-center text-danger">
  {{ chartValue | number }}
</p>
<div class="d-flex newClass" *ngIf="currentIncome">
  <p class="text-center p-3 pb-0 pt-0 startValue">
    {{startValue}}
  </p>
  <p class="text-center p-3 pb-0 pt-0 mlset">
    {{ endValue | number: "1.0-0" }}
  </p>
</div>

<div class="d-flex newClass" *ngIf="currentVolume">
  <p class="text-center p-3 pt-0 pb-0 startValue">
    {{startValue}}
  </p>
  <p class="text-center p-3 pt-0 pb-0 mlset">
    {{ endValue | number: "1.0-0" }}
  </p>
</div>

<div class="d-flex newClass" *ngIf="numberOfLoan">
  <p class="text-center p-3 pt-0 pb-0 startValue">
    {{startValue}}
  </p>
  <p class="text-center p-3 pt-0 pb-0 mlset">
    {{ endValue | number: "1.0-0" }}
  </p>
</div>
<div class="d-flex newClass" *ngIf="currentHours">
  <p class="text-center p-3 pt-0 pb-0 startValue">
    {{startValue}}
  </p>
  <p class="text-center p-3 pt-0 pb-0 mlset">
    {{ endValue | number: "1.0-0" }}
  </p>
</div>