import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { CommonFunction } from 'src/app/services/commonFunctions';

@Directive({
  selector: '[alphaNumeric]',
})
export class AlphaNumericDirective {
  @Input() validationFieldsType: string | undefined;

  constructor(private el: ElementRef) {}

  @HostListener('keypress', ['$event']) onKeyDown(event: KeyboardEvent) {
    this.validateFields(event);
  }

  // @HostListener('keyup', ['$event']) onKeyup(event: KeyboardEvent) {
  //   this.validateFields(event);
  // }

  @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
    this.validateFields(event);
  }

  validateFields(event: KeyboardEvent) {
    setTimeout(() => {

      this.el.nativeElement.value = CommonFunction.removeSpecialCharacterFromString(this.el.nativeElement.value);

      event.preventDefault();
    }, 100);
  }
}
