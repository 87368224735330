import { Component, EventEmitter, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {

  constructor() { }

  private modelRef: NgbModalRef = {} as NgbModalRef;

  config = {
    title: "Delete",
    message: "Are you sure want to delete this record ?"
  };

  ngOnInit(): void {

  }

  public static open(modalService: NgbModal, config: {
    title: string,
    message: string
  }) {
    let ref = modalService.open(ConfirmDialogComponent);

    let component = ref.componentInstance as ConfirmDialogComponent;

    component.modelRef = ref;
    component.config = config;

    return ref;
  }

  close(check: boolean) {
    this.modelRef.close(check);
  }

}
