import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { debounce } from 'typescript-debounce-decorator';

@Directive({
  selector: '[appTrimInput]'
})
export class TrimInputDirective {
  @Input() appInput: boolean | '' = true;
  constructor(private readonly control: NgControl) {}
  @HostListener('change', ['$event.target'])
  @HostListener('blur', ['$event.target'])
  // @HostListener('mouseOut', ['$event'])
  @HostListener('mouseout', ['$event.target'])
  // @HostListener('keyup', ['$event.target'])
  @debounce(100)
  onInput(input: HTMLInputElement): void {
    let value = input?.value?.trim();
    if (this.appInput || this.appInput === '') this.control?.control?.setValue(value);
  }
}
