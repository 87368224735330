import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { apiRoutes } from '../Core/apiRoutes.enum';
import { IFAQ } from '../Models/faq.interface';

@Injectable({
  providedIn: 'root'
})
export class FAQService {
  private baseApiUrl = environment.baseApiUrl;

  constructor(private http: HttpClient) {}

  public add(question: IFAQ) {
    return this.http.post(
      `${this.baseApiUrl + apiRoutes.FAQ}`,
      question
    );
  }

  public edit(question: IFAQ) {
    return this.http.put(
      `${this.baseApiUrl + apiRoutes.FAQ}`,
      question
    );
  }

  public delete(question: IFAQ) {
    return this.http.delete(`${this.baseApiUrl + apiRoutes.FAQ}`, {
      body: question,
    });
  }

  public getById(id: string) {
    return this.http.get(
      `${this.baseApiUrl + apiRoutes.FAQ + '/GetById?id='+ id}`
    );
  }

  public getAll() {
    return this.http.get<IFAQ[]>(
      `${this.baseApiUrl + apiRoutes.FAQ}`
    );
  }

}
