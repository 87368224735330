<div class="model-popup-wrapper profit-course add-new-course-popup dashboard-popup">
  <div class="model-popup-inner">
    <div class="model-popup">
      <div class="model-popup-title d-flex mb-4">
        <h3>Navigation</h3>
        <button type="button" class="btn-close" (click)="closePopup(false)" aria-label="Close"></button>
      </div>
      <div class="question-bottom-wrapper">
        <div class="question-bottom-inner">
          <div class="dashboard-content d-flex flex-wrap align-items-start">
            <ng-container *ngIf="isProfitAdviser"
              ><div class="dashboard-box">
                <a href="javascript:void(0);" (click)="redirectToAssessment()" [routerLink]="['/pages/assessment']">
                  <img src="/assets/images/dashboard/assessment.png" alt="assessment" />
                </a>
              </div>
              <div class="dashboard-box">
                <a href="javascript:void(0);" (click)="redirectToSocialMediaPost()" [routerLink]="['/pages/brandNewClass']">
                  <img src="/assets/images/dashboard/brand-new.png" alt="brandNewClass" />
                </a>
              </div>
              <div class="dashboard-box">
                <a href="javascript:void(0);" (click)="redirectToClassesToTeach()" [routerLink]="['/pages/agentProfitPlan']">
                  <img src="/assets/images/dashboard/classes-to-teach.png" alt="classes-to-teach" />
                </a>
              </div>
              <div class="dashboard-box">
                <a href="javascript:void(0);" (click)="redirectToScripts()" [routerLink]="['/pages/agentProfitScript']">
                  <img src="/assets/images/dashboard/scripts.png" alt="scripts" />
                </a>
              </div>
              <div class="dashboard-box">
                <a href="javascript:void(0);" (click)="redirectToSystem()" [routerLink]="['/pages/agentProfitUses']">
                  <img src="/assets/images/dashboard/how-to-use-the-system.png" alt="how-to-use-the-system" />
                </a>
              </div>
              <div class="dashboard-box">
                <a href="javascript:void(0);" (click)="redirectToBizPlan()" [routerLink]="['/pages/weeklyPlans']">
                  <img src="/assets/images/dashboard/your-biz-plan.png" alt="your-biz-plan" />
                </a>
              </div>
              <!-- <div class="dashboard-box">
                <a href="javascript:void(0);" (click)="redirectToGuidance()" [routerLink]="['/pages/guidance']">
                  <img src="/assets/images/dashboard/guidance.png" alt="guidance" />
                </a>
              </div> -->
              <div class="dashboard-box">
                <a href="javascript:void(0);" (click)="redirectToPreviousCalls()" [routerLink]="['/pages/previousCoachingCalls']">
                  <img src="/assets/images/dashboard/previous-calls.png" alt="previous-calls" />
                </a>
              </div>
              <div class="dashboard-box">
                <a href="javascript:void(0);" (click)="redirectToMagazine()" [routerLink]="['/pages/magazine']">
                  <img src="/assets/images/dashboard/publish-your-magazine.png" alt="publish-your-magazine" />
                </a>
              </div>
              <div class="dashboard-box">
                <a href="javascript:void(0);" (click)="redirectToCommercialLoans()" [routerLink]="['/pages/commercialLoans']">
                  <img src="/assets/images/dashboard/commercial-loans.png" alt="commercial-loans" />
                </a>
              </div>
              <div class="dashboard-box">
                <a href="javascript:void(0);" (click)="redirectToChatgpt()" [routerLink]="['/pages/chatGpt']">
                  <img src="/assets/images/dashboard/chatgpt.png" alt="chatgpt" />
                </a>
              </div>
              <div class="dashboard-box">
                <a href="javascript:void(0);" (click)="redirectToPublishYourBook()" [routerLink]="['/pages/publishYourBook']">
                  <img src="/assets/images/dashboard/publish-your-book.png" alt="publish-your-book" />
                </a>
              </div>
              <div class="dashboard-box">
                <a href="javascript:void(0);" (click)="redirectToSocialMediaPost()" [routerLink]="['/pages/socialMediaPost']">
                  <img src="/assets/images/dashboard/social-media-post.png" alt="social-media-post" />
                </a>
              </div>
              <div class="dashboard-box">
                <a href="javascript:void(0);" (click)="redirectToPreviousCalls()" [routerLink]="['/pages/howtohostevent']">
                  <img src="/assets/images/dashboard/how-to-host-event.png" alt="how-to-host-event" />
                </a>
              </div>
              <div class="dashboard-box">
                <a href="javascript:void(0);" (click)="redirectToPreviousCalls()" [routerLink]="['/pages/popbyideas']">
                  <img src="/assets/images/dashboard/popbyideas.jpg" alt="pop-by-ideas" />
                </a>
              </div>
              <div class="dashboard-box">
                <a href="javascript:void(0);" (click)="redirectToMessageBoard()" [routerLink]="['/pages/messageboard']">
                  <img src="/assets/images/dashboard/message-board.png" alt="message-board" />
                </a>
              </div>
              <div class="dashboard-box">
                <a href="javascript:void(0);" (click)="redirectToPreviousCalls()" [routerLink]="['/pages/textingcampaigns']">
                  <img src="/assets/images/dashboard/texting-campaigns.jpg" alt="texting-campaigns" />
                </a>
              </div>
            </ng-container>

            <ng-container *ngIf="isAdmin && isNotAgent">
              <div class="dashboard-box">
                <a href="javascript:void(0);" (click)="redirectToAssessment()" [routerLink]="['/pages/assessment']">
                  <img src="/assets/images/dashboard/assessment.png" alt="assessment" />
                </a>
              </div>
              <div class="dashboard-box">
                <a href="javascript:void(0);" (click)="redirectToQuestionConfig()" [routerLink]="['/pages/questionconfig']">
                  <img src="/assets/images/dashboard/question-config.png" alt="question-config" />
                </a>
              </div>
            </ng-container>

            <ng-container *ngIf="isAdmin && !isNotAgent">
              <div class="dashboard-box">
                <a href="javascript:void(0);" (click)="redirectToAdvisor()" [routerLink]="['/pages/profitofficer']">
                  <img src="/assets/images/dashboard/view-your-advicer.png" alt="view-your-advisor" />
                </a>
              </div>
              <div class="dashboard-box">
                <a href="javascript:void(0);" (click)="redirectToQuestionConfig()" [routerLink]="['/pages/questionconfig']">
                  <img src="/assets/images/dashboard/question-config.png" alt="question-config" />
                </a>
              </div>
              <div class="dashboard-box">
                <a href="javascript:void(0);" (click)="redirectToSocialMediaPost()" [routerLink]="['/pages/brandNewClass']">
                  <img src="/assets/images/dashboard/brand-new.png" alt="brandNewClass" />
                </a>
              </div>
              <div class="dashboard-box">
                <a href="javascript:void(0);" (click)="redirectToAgent()" [routerLink]="['/pages/agents']">
                  <img src="/assets/images/dashboard/view-your-agent.png" alt="view-your-agent" />
                </a>
              </div>
              <div class="dashboard-box">
                <a href="javascript:void(0);" (click)="redirectToArchive()" [routerLink]="['/pages/unarchive']">
                  <img src="/assets/images/dashboard/archive.png" alt="archive" />
                </a>
              </div>
              <div class="dashboard-box">
                <a href="javascript:void(0);" (click)="redirectToClassesToTeach()" [routerLink]="['/pages/agentProfitPlan']">
                  <img src="/assets/images/dashboard/classes-to-teach.png" alt="classes-to-teach" />
                </a>
              </div>
              <div class="dashboard-box">
                <a href="javascript:void(0);" (click)="redirectToScripts()" [routerLink]="['/pages/agentProfitScript']">
                  <img src="/assets/images/dashboard/scripts.png" alt="scripts" />
                </a>
              </div>
              <div class="dashboard-box">
                <a href="javascript:void(0);" (click)="redirectToSystem()" [routerLink]="['/pages/agentProfitUses']">
                  <img src="/assets/images/dashboard/how-to-use-the-system.png" alt="how-to-use-the-system" />
                </a>
              </div>
              <div class="dashboard-box">
                <a href="javascript:void(0);" (click)="redirectToBizPlan()" [routerLink]="['/pages/weeklyPlans']">
                  <img src="/assets/images/dashboard/your-biz-plan.png" alt="your-biz-plan" />
                </a>
              </div>
              <!-- <div class="dashboard-box">
                <a href="javascript:void(0);" (click)="redirectToGuidance()" [routerLink]="['/pages/guidance']">
                  <img src="/assets/images/dashboard/guidance.png" alt="guidance" />
                </a>
              </div> -->
              <div class="dashboard-box">
                <a href="javascript:void(0);" (click)="redirectToPreviousCalls()" [routerLink]="['/pages/previousCoachingCalls']">
                  <img src="/assets/images/dashboard/previous-calls.png" alt="previous-calls" />
                </a>
              </div>
              <div class="dashboard-box">
                <a href="javascript:void(0);" (click)="redirectToMagazine()" [routerLink]="['/pages/magazine']">
                  <img src="/assets/images/dashboard/publish-your-magazine.png" alt="publish-your-magazine" />
                </a>
              </div>
              <div class="dashboard-box">
                <a href="javascript:void(0);" (click)="redirectToCommercialLoans()" [routerLink]="['/pages/commercialLoans']">
                  <img src="/assets/images/dashboard/commercial-loans.png" alt="commercial-loans" />
                </a>
              </div>
              <div class="dashboard-box">
                <a href="javascript:void(0);" (click)="redirectToCommercialLoans()" [routerLink]="['/pages/chatGpt']">
                  <img src="/assets/images/dashboard/chatgpt.png" alt="chatgpt" />
                </a>
              </div>
              <div class="dashboard-box">
                <a href="javascript:void(0);" (click)="redirectToPublishYourBook()" [routerLink]="['/pages/publishYourBook']">
                  <img src="/assets/images/dashboard/publish-your-book.png" alt="publish-your-book" />
                </a>
              </div>
              <div class="dashboard-box">
                <a href="javascript:void(0);" (click)="redirectToSocialMediaPost()" [routerLink]="['/pages/socialMediaPost']">
                  <img src="/assets/images/dashboard/social-media-post.png" alt="social-media-post" />
                </a>
              </div>
              <div class="dashboard-box">
                <a href="javascript:void(0);" (click)="redirectToPreviousCalls()" [routerLink]="['/pages/howtohostevent']">
                  <img src="/assets/images/dashboard/how-to-host-event.png" alt="how-to-host-event" />
                </a>
              </div>
              <div class="dashboard-box">
                <a href="javascript:void(0);" (click)="redirectToPreviousCalls()" [routerLink]="['/pages/popbyideas']">
                  <img src="/assets/images/dashboard/popbyideas.jpg" alt="pop-by-ideas" />
                </a>
              </div>
              <div class="dashboard-box">
                <a href="javascript:void(0);" (click)="redirectToMessageBoard()" [routerLink]="['/pages/messageboard']">
                  <img src="/assets/images/dashboard/message-board.png" alt="message-board" />
                </a>
              </div>
              <div class="dashboard-box">
                <a href="javascript:void(0);" (click)="redirectToPreviousCalls()" [routerLink]="['/pages/textingcampaigns']">
                  <img src="/assets/images/dashboard/texting-campaigns.jpg" alt="texting-campaigns" />
                </a>
              </div>
            </ng-container>

            <ng-container *ngIf="isAgent">
              <div class="dashboard-box">
                <a href="javascript:void(0);" (click)="redirectToAssessment()" [routerLink]="['/pages/assessment']">
                  <img src="/assets/images/dashboard/assessment.png" alt="assessment" />
                </a>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
