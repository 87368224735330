import { BehaviorSubject } from "rxjs";
import { IUser } from "src/app/Models/user.interface";

export class Storage {
  public static userDataEvent = new BehaviorSubject<IUser>({} as IUser);

  public static setUserData(user: IUser) {
    window.localStorage.setItem('user', JSON.stringify(user));
    this.userDataEvent.next(user);
  }
  public static setParentUri(uri: string) {
    return window.localStorage.setItem('parentUri', uri);
  }
  public static getParentUri() {
    return window.localStorage.getItem('parentUri') ?? '';
  }
  public static removeParentUri() {
    window.localStorage.removeItem('parentUri');
  }

  public static getUserData(): IUser | null {
    const user = window.localStorage.getItem('user');
    if (user) {
      return JSON.parse(user) as IUser;
    }

    return null;
  }

  public static setToken(token: string) {
    return window.localStorage.setItem('token', token);
  }

  public static getToken() {
    return window.localStorage.getItem('token') ?? '';
  }
  public static removeUserData() {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('user');
    window.localStorage.clear();
    this.userDataEvent.next({} as IUser);
  }

  public static getEmptyGuid() {
    return '00000000-0000-0000-0000-000000000000';
  }
}
