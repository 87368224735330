<div class="model-popup-wrapper">
  <div class="model-popup-inner">
    <div class="model-popup">
      <div class="model-popup-title d-flex justify-content-between">
        <h4>Saved Video</h4>
        <!-- {{testPath}} -->
        <button type="button" class="btn-close btn-close-white" (click)="close()" aria-label="Close"></button>
      </div>

      <div class="model-popup-content">
        <!-- <div #playerContainer></div> -->
        <ng-container *ngIf="!isInvalidFormat">
          <div #videoTemplate></div>
        </ng-container>
        <ng-container *ngIf="isInvalidFormat">
          <div class="d-flex flex-wrap align-items-center justify-content-center py-3">
            <p class="model-popup-content p w-100 mb-3">Format is not supported. please download this video</p>
            <a href="javascript:void(0);" (click)="$event.stopPropagation(); onDownloadClick()" class="d-flex flex-wrap align-items-center" style="color: #fa7a23;">
              
              <svg class="me-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M13 10H18L12 16L6 10H11V3H13V10ZM4 19H20V12H22V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V12H4V19Z" fill="rgba(250,122,35,1)"></path></svg>
              Download
            </a>
          </div>
        </ng-container>
        <!-- <video width="320" height="240" autoplay muted poster="/assets/images/video-img.jpg">
          <source [src]="courseImages + videoPath" type="video/mp4" />
          Your browser does not support the video tag.
        </video> -->
      </div>
    </div>
  </div>
</div>