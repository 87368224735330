import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css'],
})
export class LoaderComponent implements OnInit {
  modalRef: NgbModalRef | null = null;

  constructor() {}

  ngOnInit(): void {}

  public static open(modalService: NgbModal) {
    let ref = modalService.open(LoaderComponent, {
      modalDialogClass: 'LoaderModel'
    });

    let component = ref.componentInstance as LoaderComponent;

    component.modalRef = ref;

    return component;
  }

  close() {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }
}
