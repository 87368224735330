

export class CommonFunction {

    public static removeSpecialCharacterFromString(str: string): string {

        if (!str || str == '') return '';

        str = str.trim();

        return str.replace(
            /[^a-zA-Z0-9S]/g,
            ''
        );
    }

    public static youtubeUrlToEmbedUrl(url: string): string {

        var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        var match = url.match(regExp);
        if (match && match[2].length == 11) {
            return "https://www.youtube.com/embed/" + match[2]
        } else if (url.indexOf("vimeo.com") !== -1) {
            return url
        } else {
            return ''
        }
    }

}


