import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import Chart from 'chart.js/auto';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.css']
})
export class BarChartComponent implements OnInit {
  @ViewChild('canvasContainer', { static: false }) chartContainer: ElementRef = {} as ElementRef;

  @Input() chartType: 'Income1' | 'Income2' | 'Unit1' | 'Unit2' | 'hours1' | 'hours2' | 'Volume1' | 'Volume2' = 'Income1';

  @Input() set setTotalPerAndUnit(value: { totalPer: number; totalUnits: number }) {
    this.totalUnits = this.changeToNumber(value.totalUnits);
    this.totalPer = this.changeToNumber(value.totalPer);

    this.setUnitChart();

    this.setVolumeChart();

    this.setIncomeChart();
    this.setHoursChart();
  }

  @Input() set setCurrentIncome(income: number) {
    this.currentIncome = this.changeToNumber(income);
    this.setIncomeChart();
  }

  @Input() set setCurrentHours(hours: number) {
    this.currentHours = this.changeToNumber(hours);
    this.setHoursChart();
    // this.setIncomeChart();
  }
  @Input() set setCurrentHoursIncome(income: number) {
    this.currentHoursIncome = this.changeToNumber(income);
    this.setHoursChart();
    // this.setIncomeChart();
  }

  @Input() set setCurrentVolume(volume: number) {
    this.currentVolume = this.changeToNumber(volume);

    this.setVolumeChart();
  }

  @Input() set setNoOfLoan(loans: number) {
    this.numberOfLoan = this.changeToNumber(loans);

    this.setUnitChart();

    this.setIncomeChart();
  }

  @Input() set setAvgLoanSize(loanSize: number) {
    this.avgLoanSize = this.changeToNumber(loanSize);

    if (this.avgLoanSize > 0) this.commission = this.avgLoanSize / this.basicPoints;

    this.setVolumeChart();

    this.setIncomeChart();
    this.setHoursChart();
  }

  @Input() set setBasicPoints(points: number) {
    this.basicPoints = this.changeToNumber(points);

    if (this.avgLoanSize > 0) this.commission = this.avgLoanSize / this.basicPoints;

    this.setIncomeChart();
    this.setHoursChart();
  }

  private chart: any;

  public chartValue: number = 0;

  public chartTotalValue: number = 0;

  public chartCount: number = 2;

  public currentIncome: number = 0;

  public currentHours: number = 0;

  public commission: number = 0;

  public currentVolume: number = 0;

  public numberOfLoan: number = 0;

  public avgLoanSize: number = 0;

  public basicPoints: number = 0;

  public multiplicationFactor: number = 0.2;

  public totalPer: number = 0;

  public totalUnits: number = 0;
  public currentHoursIncome: number = 0;

  public startValue: number = 0;
  public endValue: number = 0;

  constructor() { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.generateChart();
  }

  private setIncomeChart() {
    try {
      if (this.chartType == 'Income1') {
        var perIncome = this.currentIncome * this.totalPer == 0 ? 0 : (this.currentIncome * this.totalPer) / 100;
        let commission = 0;

        if (this.avgLoanSize > 0 && this.basicPoints > 0) {
          commission = this.avgLoanSize / this.basicPoints;
        }
        var unitIncome = commission * this.totalUnits;
        var totalIncome = this.currentIncome + perIncome + unitIncome;

        this.chartValue = totalIncome;
        if (this.chartValue > this.currentIncome * this.chartCount) {
          this.chartTotalValue = this.chartValue * this.multiplicationFactor + this.chartValue - this.currentIncome;
        } else {
          this.chartTotalValue = this.currentIncome * this.chartCount - this.currentIncome;
        }

        this.startValue = 0;

        let totalValue = this.chartTotalValue + this.currentIncome;
        let roundedValue = this.getRoundOfToThousand(totalValue);

        let val = roundedValue - (this.chartTotalValue + this.currentIncome);
        this.chartTotalValue = val + this.chartTotalValue;

        this.endValue = roundedValue;
        this.chartValue = parseFloat(this.currentIncome.toFixed(2));
        this.generateChart();
      } else if (this.chartType == 'Income2') {
        var perIncome = this.currentIncome * this.totalPer == 0 ? 0 : (this.currentIncome * this.totalPer) / 100;
        let commission = 0;

        if (this.avgLoanSize > 0 && this.basicPoints > 0) {
          commission = this.avgLoanSize / this.basicPoints;
        }

        var unitIncome = commission * this.totalUnits;
        var totalIncome = this.currentIncome + perIncome + unitIncome;
        this.chartValue = totalIncome;
        if (this.chartValue > this.currentIncome * this.chartCount) {
          this.chartTotalValue = this.chartValue * this.multiplicationFactor;
        } else {
          this.chartTotalValue = this.currentIncome * this.chartCount - this.chartValue;
        }

        this.startValue = 0;
        this.chartValue = parseFloat(this.chartValue.toFixed(2));

        let totalValue = this.chartTotalValue + this.chartValue;

        let roundedValue = this.getRoundOfToThousand(totalValue);

        let val = roundedValue - (this.chartTotalValue + this.chartValue);
        this.chartTotalValue = val + this.chartTotalValue;
        this.endValue = this.getRoundOfToThousand(totalValue);
        this.generateChart();
      }
    } catch (e) {
    }
  }

  private setVolumeChart() {
    try {
      if (this.chartType == 'Volume1') {
        this.chartValue = this.currentVolume + this.avgLoanSize * this.totalUnits;
        if (this.chartValue > this.currentVolume * this.chartCount) {
          this.chartTotalValue = this.chartValue * this.multiplicationFactor;
        } else {
          this.chartTotalValue = this.currentVolume * this.chartCount - this.chartValue;
        }
        this.startValue = 0;

        let totalValue = this.chartTotalValue + this.chartValue;

        let roundedValue = this.getRoundOfToThousand(totalValue);

        this.endValue = roundedValue;
        this.chartValue = parseFloat(this.currentVolume.toFixed(2));
        this.chartTotalValue = this.endValue - this.chartValue;

        let val = roundedValue - (this.chartTotalValue + this.chartValue);
        this.chartTotalValue = val + this.chartTotalValue;
        this.generateChart();
      } else if (this.chartType == 'Volume2') {
        this.chartValue = parseFloat((this.currentVolume + this.avgLoanSize * this.totalUnits).toFixed(2));

        if (this.chartValue > this.currentVolume * this.chartCount) {
          this.chartTotalValue = this.chartValue * this.multiplicationFactor;
        } else {
          this.chartTotalValue = this.currentVolume * this.chartCount - this.chartValue;
        }
        this.startValue = 0;
        this.chartValue = parseFloat(this.chartValue.toFixed(2));

        let totalValue = this.chartTotalValue + this.chartValue;

        let roundedValue = this.getRoundOfToThousand(totalValue);

        this.endValue = roundedValue;

        let val = roundedValue - (this.chartTotalValue + this.chartValue);
        this.chartTotalValue = val + this.chartTotalValue;
        this.generateChart();
      }
    } catch (e) {
    }
  }

  private setUnitChart() {
    try {
      if (this.chartType == 'Unit1') {
        this.chartValue = this.numberOfLoan + this.totalUnits;
        if (this.chartValue > this.numberOfLoan * this.chartCount) {
          this.chartTotalValue = this.chartValue * this.multiplicationFactor;
        } else {
          this.chartTotalValue = this.numberOfLoan * this.chartCount - this.chartValue;
        }
        this.startValue = 0;

        let totalValue = this.chartTotalValue + this.chartValue;

        let roundedValue = this.getRoundOfToThousand(totalValue);

        this.endValue = roundedValue;

        this.chartValue = parseFloat(this.numberOfLoan.toFixed(2));
        this.chartTotalValue = this.endValue - this.chartValue;

        let val = roundedValue - (this.chartTotalValue + this.chartValue);
        this.chartTotalValue = val + this.chartTotalValue;

        this.generateChart();
      } else if (this.chartType == 'Unit2') {
        this.chartValue = this.numberOfLoan + this.totalUnits;

        if (this.chartValue > this.numberOfLoan * this.chartCount) {
          this.chartTotalValue = this.chartValue * this.multiplicationFactor;
        } else {
          this.chartTotalValue = this.numberOfLoan * this.chartCount - this.chartValue;
        }
        this.startValue = 0;

        let totalValue = this.chartTotalValue + this.chartValue;

        let roundedValue = this.getRoundOfToThousand(totalValue);

        this.endValue = roundedValue;
        this.startValue = 0;

        this.chartValue = parseFloat(this.chartValue.toFixed(2));

        let val = roundedValue - (this.chartTotalValue + this.chartValue);
        this.chartTotalValue = val + this.chartTotalValue;
        this.generateChart();
      }
    } catch (e) {
    }
  }

  private setHoursChart() {
    // Formula 
    // (currentIncome  + perIncome + (avgLoanSize / basicPoints) * totalUnits) / hours

    try {
      if (this.chartType == 'hours1') {
        var perIncome = this.currentHoursIncome * this.totalPer == 0 ? 0 : (this.currentHoursIncome * this.totalPer) / 100;

        let commission = 0;
        if (this.avgLoanSize > 0 && this.basicPoints > 0) {
          commission = this.avgLoanSize / this.basicPoints;
        }

        var unitIncome = commission * this.totalUnits;
        var totalIncome = this.currentHoursIncome + perIncome + unitIncome;
        this.chartValue = totalIncome / this.currentHours;
        if (this.chartValue > (this.currentHoursIncome / this.currentHours) * this.chartCount) {
          this.chartTotalValue = this.chartValue * this.multiplicationFactor;
        } else {
          this.chartTotalValue = (this.currentHoursIncome / this.currentHours) * this.chartCount - this.chartValue;
        }
        this.startValue = 0;

        let totalValue = this.chartTotalValue + this.chartValue;

        let roundedValue = this.getRoundOfToHundred(totalValue);

        this.endValue = roundedValue;
        this.chartValue = this.currentHoursIncome / this.currentHours;
        this.chartTotalValue = this.endValue - this.chartValue;
        this.chartValue = parseFloat((this.currentHoursIncome / this.currentHours).toFixed(2));

        // if (this.chartValue > 1)
        //   this.chartValue = parseInt(this.chartValue.toFixed(2));
        // if (this.chartTotalValue > 1)
        //   this.chartTotalValue = parseInt(this.chartTotalValue.toFixed(2));

        let val = roundedValue - (this.chartTotalValue + this.chartValue);
        this.chartTotalValue = val + this.chartTotalValue;
        this.generateChart();
      } else if (this.chartType == 'hours2') {
        var perIncome = this.currentHoursIncome * this.totalPer == 0 ? 0 : (this.currentHoursIncome * this.totalPer) / 100;

        let commission = 0;

        if (this.avgLoanSize > 0 && this.basicPoints > 0) {
          commission = this.avgLoanSize / this.basicPoints;
        }

        var unitIncome = commission * this.totalUnits;
        var totalIncome = this.currentHoursIncome + perIncome + unitIncome;
        this.chartValue = totalIncome / this.currentHours;
        if (this.chartValue > (this.currentHoursIncome / this.currentHours) * this.chartCount) {
          this.chartTotalValue = this.chartValue * this.multiplicationFactor;
        } else {
          this.chartTotalValue = (this.currentHoursIncome / this.currentHours) * this.chartCount - this.chartValue;
        }
        this.startValue = 0;

        let totalValue = this.chartTotalValue + this.chartValue;

        let roundedValue = this.getRoundOfToHundred(totalValue);
        this.endValue = roundedValue;
        // this.chartValue = parseInt(this.chartValue.toFixed(2));
        // if (this.chartValue > 1)
        // if (this.chartTotalValue > 1)
        //   this.chartTotalValue = parseInt(this.chartTotalValue.toFixed(2));
        this.chartValue = parseFloat(this.chartValue.toFixed(2));

        let val = roundedValue - (this.chartTotalValue + this.chartValue);
        this.chartTotalValue = val + this.chartTotalValue;
        this.generateChart();
      }
    } catch (e) {
    }
  }

  private generateChart() {
    if (!this.chartContainer) return;

    let chartContainer = this.chartContainer.nativeElement as HTMLElement;

    if (!chartContainer) return;

    chartContainer.innerHTML = '';

    var cvs = document.createElement('canvas');

    chartContainer.appendChild(cvs);

    if (!cvs) return;

    if (this.chart) this.chart.destroy();
    this.chart = new Chart(cvs, {
      type: 'doughnut',
      plugins: [
        {
          id: 'custom_canvas_background_color',
          beforeDraw: (chart, args, options) => {
            let needleValue: any = this.chartValue;
            let dataTotal: any = chart.config.data.datasets[0].data.reduce((a, b) => ((a as any) + b) as any, 0);
            let angle = Math.PI + (1 / dataTotal) * needleValue * Math.PI;
            let cw = chart.canvas.offsetWidth;
            let ch = chart.canvas.offsetHeight;
            let cx = cw / 2;
            let cy = ch - 6;
            const { ctx } = chart;
            ctx.translate(cx, cy);
            ctx.rotate(angle);
            ctx.beginPath();
            ctx.moveTo(0, -3);
            ctx.lineTo(ch - 20, 0);
            ctx.lineTo(0, 3);
            ctx.fillStyle = 'rgb(0, 0, 0)';
            ctx.fill();
            ctx.rotate(-angle);
            ctx.translate(-cx, -cy);
            ctx.beginPath();
            ctx.arc(cx, cy, 5, 0, Math.PI * 2);
            ctx.fill();
          }
        }
      ],
      data: {
        labels: [],

        datasets: [
          {
            data: [this.chartValue, this.chartTotalValue],

            backgroundColor: ['#73D12A', '#231F20', 'rgba(63, 191, 63, 0.2)']
          }
        ]
      },
      options: <any>{
        responsive: false,
        aspectRatio: 2,

        layout: {
          padding: {
            bottom: 3
          }
        },
        needleValue: this.chartValue,
        rotation: -90,
        cutout: '50%',
        circumference: 180,
        legend: {
          display: false
        },
        // cutoutPercentage: 75,
        tooltips: {
          enabled: true
        },
        animation: {
          animateRotate: false,
          animateScale: true
        }
      }
    });
  }

  private getRoundOfToThousand(round: number) {
    let number = 0;
    var rest = round % 1000;
    if (rest == 0) return round;

    if (rest > 500) {
      number = round - rest + 1000;
    } else {
      number = round - rest + 500;
    }

    return number;
  }

  private getRoundOfToHundred(round: number) {
    let number = 0;
    var rest = round % 100;

    if (rest == 0) return round;
    if (rest > 50) {
      number = round - rest + 100;
    } else {
      number = round - rest + 50;
    }

    return number;
  }


  private changeToNumber(value: any): number {
    try {
      return Number(value);
    } catch (e) {
      return 0;
    }
  }
}
