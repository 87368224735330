import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {appRoute} from 'src/app/Core/routes.enum';
import {Storage} from 'src/app/Core/storage';
import {UserRoleEnum} from 'src/app/Core/userRole.enum';
import {IUser} from 'src/app/Models/user.interface';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-dashboard-popup',
  templateUrl: './dashboard-popup.component.html',
  styleUrls: ['./dashboard-popup.component.css']
})
export class DashboardPopupComponent implements OnInit {
  private modelRef: NgbModalRef = {} as NgbModalRef;
  public userData: IUser = {} as IUser;
  isProfitAdviser: boolean = false;
  isNotAgent: boolean = false;
  isAgent: boolean = false;
  isAdmin: boolean = false;
  constructor(private router: Router) {}

  async ngOnInit() {
    this.userData = (await Storage.getUserData()) ?? ({} as IUser);
    if (this.userData.role === UserRoleEnum.ProfitAdviser) {
      this.isProfitAdviser = true;
    } else if (this.userData.role == UserRoleEnum.Admin) {
      this.isAdmin = true;
      if (!environment.isAgent) {
        this.isNotAgent = true;
      } else {
        this.isNotAgent = false;
      }
    } else {
      this.isAgent = true;
    }
  }

  public static open(modalService: NgbModal) {
    var ref = modalService.open(DashboardPopupComponent);
    ref.componentInstance.modelRef = ref;
    return ref;
  }

  closePopup(isSubmitted: boolean = false) {
    this.modelRef.close({
      isSubmitted: isSubmitted
    });
  }

  redirectToAssessment() {
    this.modelRef.close({
      isSubmitted: false
    });
  }

  redirectToAdvisor() {
    this.modelRef.close({
      isSubmitted: false
    });
  }
  redirectToQuestionConfig() {
    this.modelRef.close({
      isSubmitted: false
    });
  }
  redirectToAgent() {
    this.modelRef.close({
      isSubmitted: false
    });
  }
  redirectToArchive() {
    this.modelRef.close({
      isSubmitted: false
    });
  }
  redirectToClassesToTeach() {
    this.modelRef.close({
      isSubmitted: false
    });
  }
  redirectToScripts() {
    this.modelRef.close({
      isSubmitted: false
    });
  }
  redirectToSystem() {
    this.modelRef.close({
      isSubmitted: false
    });
  }
  redirectToBizPlan() {
    this.modelRef.close({
      isSubmitted: false
    });
  }
  redirectToGuidance() {
    this.modelRef.close({
      isSubmitted: false
    });
  }
  redirectToPreviousCalls() {
    this.modelRef.close({
      isSubmitted: false
    });
  }
  redirectToMagazine() {
    this.modelRef.close({
      isSubmitted: false
    });
  }
  redirectToCommercialLoans() {
    this.modelRef.close({
      isSubmitted: false
    });
  }
  redirectToChatgpt() {
    this.modelRef.close({
      isSubmitted: false
    });
  }
  redirectToPublishYourBook() {
    this.modelRef.close({
      isSubmitted: false
    });
  }
  redirectToSocialMediaPost() {
    this.modelRef.close({
      isSubmitted: false
    });
  }
  redirectToMessageBoard() {
    this.modelRef.close({
      isSubmitted: false
    });
  }
}
