export enum accountRoutes {
  account = 'account',
  login = 'login',
  welcome = 'welcome',
  signup = 'signup',
  profitOfficerSignup = 'profitadvisersignup',
  forgotpassword = 'forgotpassword',
  resetpassword = 'resetpassword',
  resetpasswordafterverification = 'resetpasswordafterverification',
  sendotp = 'sendotp',
  verifyotp = 'verifyotp',
  eventSignup = 'eventsignup',
  agentSignup = 'signup',
  verifyAccount = 'verifyaccount',
  thanks = 'thanks',
  agentSignUp2 = 'agentsignup',
  agentWelcome = 'agentwelcome',
  agentLanding = 'agentlanding'
}

export enum appRoute {
  pages = 'pages',
  appoitments = 'appoitments',
  eventSchedule = 'scheduleevent',
  questionConfig = 'questionconfig',
  profitofficer = 'profitofficer',
  assessment = 'assessment',
  newAssessment = 'newassessment',
  agentAssessment = 'agentassessment',
  changepassword = 'changepassword',
  userprofile = 'userprofile',
  downloadPdf = 'downlaodpdf',
  inquiry = 'inquiry',
  poInquiry = 'poinquiry',
  agents = 'agents',
  faq = 'faq',
  unarchive = 'unarchive',
  adminassessment = 'adminassessment',
  agentProfitPlan = 'agentProfitPlan',
  agentProfitPlanlist = 'agentProfitPlanlist',
  agentProfitScript = 'agentProfitScript',
  agentProfitUses = 'agentProfitUses',
  agentProfitUsesList = 'agentProfitUsesList',
  weeklyPlans = 'weeklyPlans',
  agentScriptCallsClasses = 'agentScriptCallsClasses',
  weeklyPlanSuccessList = 'weeklyPlanSuccessList',
  guidance = 'guidance',
  previousCoachingCalls = 'previousCoachingCalls',
  PreviousCoachingCallsVideo = 'PreviousCoachingCallsVideo',
  PreviousCoachingCallsVideoDetails = 'PreviousCoachingCallsVideoDetails',
  iframeTest = 'iframeTest',
  magazine = 'magazine',
  magazineVideo = 'magazineVideo',
  magazineVideoDetails = 'magazineVideoDetails',
  commercialLoans = 'commercialLoans',
  commercialLoanVideo = 'commercialLoanVideo',
  commercialLoanVideoDetails = 'commercialLoanVideoDetails',
  messageboard = 'messageboard',
  chatGpt = 'chatGpt',
  chatGptVideo = 'chatGptVideo',
  publishYourBook = 'publishYourBook',
  publishYourBookVideo = 'publishYourBookVideo',
  socialMediaPost = 'socialMediaPost',
  dashboard = 'dashboard',
  brandNewClass = 'brandNewClass',
  brandNewClassesVideo = 'brandNewClassesVideo',
  howtohostevent = 'howtohostevent',
  howtohosteventvideo = 'howtohosteventvideo',
  popbyideas = 'popbyideas',
  popbyideasplan = 'popbyideasplan',
  textingcampaigns = 'textingcampaigns',
  chatbox = 'chat-box',
  subsubject = 'sub-subject'
}
