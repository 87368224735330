import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { accountRoutes } from 'src/app/Core/routes.enum';
import { Storage } from 'src/app/Core/storage';


@Injectable({
  providedIn: 'root'
})
export class ScheduleEventGuard implements CanActivate {

  /**
   *
   */
  constructor(private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let user = Storage.getUserData() as any;
    if (user == null) {
      this.router.navigateByUrl(accountRoutes.account + "/" + accountRoutes.signup);
      return false;
    }

    user = user as any;

    if (!user?.isEndUser) {
      this.router.navigate([accountRoutes.account + "/" + accountRoutes.signup]);
      return false;
    }

    return true;
  }


}
