import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ThemeRoutingModule } from './theme-routing.module';
import { SideBarComponent } from './side-bar/side-bar.component';
import { HeaderComponent } from './header/header.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [SideBarComponent, HeaderComponent],
  imports: [CommonModule, ThemeRoutingModule, NgbModule],
  exports: [SideBarComponent, HeaderComponent],
})
export class ThemeModule {}
