import { Injectable } from '@angular/core';
const countrycitystatejson = require('countrycitystatejson');

@Injectable({
  providedIn: 'root',
})
export class AddressServiceService {
  private countryData = countrycitystatejson;

  getCountries() {
    return this.countryData.getCountries();
  }

  getStatesByCountry(countryShotName: string) {
    return this.countryData.getStatesByShort(countryShotName);
  }

  getCitiesByState(country: string, state: string) {
    return this.countryData.getCities(country, state);
  }

  getCountryCodeByName(name: string) {
    let country = this.countryData.getCountries().find((x: any) => x.name == name);

    if(country != null){
      return country.shortName;
    }

    return name;
  }

  
  getCountryByShortName(shortName: string) {
    let country = this.countryData.getCountries().find((x: any) => x.shortName == shortName);

    if(country != null){
      return country.name;
    }

    return shortName;
  }
}
