import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import Chart from 'chart.js/auto';
import { AssessmentRevenueDetails } from 'src/app/Models/assessmentRevenueDetail.interface';

@Component({
  selector: 'app-gauge-chart',
  templateUrl: './gauge-chart.component.html',
  styleUrls: ['./gauge-chart.component.css']
})
export class GaugeChartComponent implements OnInit {
  @ViewChild('canvasContainer', { static: false }) chartContainer: ElementRef = {} as ElementRef;

  private chart: any;

  private chartRevenueDetail = {} as AssessmentRevenueDetails;

  private multiplicationFactor = 2;

  gaugeChartData = {
    gaugeFirstPart: 25,
    gaugeSecondPart: 50,
    gaugeName: 'income',
    gaugeStartValue: 0,
    gaugeEndValue: 0
  } as any;

  @Input() chartType: 'Income1' | 'Income2' | 'Unit1' | 'Unit2' | 'hours1' | 'hours2' | 'Volume1' | 'Volume2' = 'Income1';

  private changeToNumber(value: any): number {
    try {
      return Number(value);
    } catch (e) {
      return 0;
    }
  }

  @Input() set setRevenueDetail(data: AssessmentRevenueDetails) {
    data.averageCommission = this.changeToNumber(data.averageCommission);
    data.units = this.changeToNumber(data.units);
    data.hours = this.changeToNumber(data.hours);
    data.currentVolume = this.changeToNumber(data.currentVolume);
    data.currentIncome = this.changeToNumber(data.currentIncome);
    data.numberOfClient = this.changeToNumber(data.numberOfClient);
    data.numberOfListings = this.changeToNumber(data.numberOfListings);
    data.numberOfBuyers = this.changeToNumber(data.numberOfBuyers);
    data.averageSalesPrice = this.changeToNumber(data.averageSalesPrice);
    data.homeSold = this.changeToNumber(data.homeSold);
    data.totalPer = this.changeToNumber(data.totalPer);
    data.totalUnits = this.changeToNumber(data.totalUnits);

    this.chartRevenueDetail = data;

    this.generateChart();
  }

  constructor() { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.renderChart();
  }

  private renderChart() {
    if (!this.chartContainer) return;

    let chartContainer = this.chartContainer.nativeElement as HTMLElement;

    if (!chartContainer) return;

    chartContainer.innerHTML = '';

    var cvs = document.createElement('canvas');

    chartContainer.appendChild(cvs);

    if (!cvs) return;

    if (this.chart) this.chart.destroy();
    this.chart = new Chart(cvs, {
      type: 'doughnut',
      plugins: [
        {
          id: 'custom_canvas_background_color',
          beforeDraw: (chart, args, options) => {
            let needleValue: any = this.gaugeChartData.gaugeFirstPart;
            let dataTotal: any = chart.config.data.datasets[0].data.reduce((a, b) => ((a as any) + b) as any, 0);
            let angle = Math.PI + (1 / dataTotal) * needleValue * Math.PI;
            let cw = chart.canvas.offsetWidth;
            let ch = chart.canvas.offsetHeight;
            let cx = cw / 2;
            let cy = ch - 6;
            const { ctx } = chart;
            ctx.translate(cx, cy);
            ctx.rotate(angle);
            ctx.beginPath();
            ctx.moveTo(0, -3);
            ctx.lineTo(ch - 20, 0);
            ctx.lineTo(0, 3);
            ctx.fillStyle = 'rgb(0, 0, 0)';
            ctx.fill();
            ctx.rotate(-angle);
            ctx.translate(-cx, -cy);
            ctx.beginPath();
            ctx.arc(cx, cy, 5, 0, Math.PI * 2);
            ctx.fill();
          }
        }
      ],
      data: {
        labels: [],

        datasets: [
          {
            data: [this.gaugeChartData.gaugeFirstPart, this.gaugeChartData.gaugeSecondPart],

            backgroundColor: ['#73D12A', '#231F20', 'rgba(63, 191, 63, 0.2)']
          }
        ]
      },
      options: <any>{
        responsive: false,
        aspectRatio: 2,

        layout: {
          padding: {
            bottom: 3
          }
        },
        needleValue: this.gaugeChartData.gaugeFirstPart,
        rotation: -90,
        cutout: '50%',
        circumference: 180,
        legend: {
          display: false
        },
        // cutoutPercentage: 75,
        tooltips: {
          enabled: true
        },
        animation: {
          animateRotate: false,
          animateScale: true
        }
      }
    });
  }

  private generateChart() {
    switch (this.chartType) {
      case 'Income1':
      case 'Income2':
        this.updateIncomeChart();
        break;
      case 'Unit1':
      case 'Unit2':
        this.updateUnitChart();
        break;
      case 'Volume1':
      case 'Volume2':
        this.updateVolumeChart();
        break;
      case 'hours1':
      case 'hours2':
        this.updateHourChart();
        break;
    }

    this.renderChart();
  }

  private updateIncomeChart() {
    //Formula
    //  Previous Year Income + ( ((Avg Sales Price * Avg Commission ) / 100 ) * Total Units)

    let salesPrice = this.chartRevenueDetail.averageSalesPrice;
    let commission = this.chartRevenueDetail.averageCommission;
    let units = this.chartRevenueDetail.totalUnits;

    let formulaValue = this.chartRevenueDetail.currentIncome + ((salesPrice * commission) / 100) * units;

    this.UpdateGaugeChart(formulaValue, this.chartRevenueDetail.currentIncome);
  }

  private updateVolumeChart() {
    // Formula
    // Previous Year Volume+ (Avg Sales Price * Total Units)

    let formulaValue = this.chartRevenueDetail.currentVolume + this.chartRevenueDetail.averageSalesPrice * this.chartRevenueDetail.totalUnits;

    this.UpdateGaugeChart(formulaValue, this.chartRevenueDetail.currentVolume);
  }

  private updateUnitChart() {
    //formula
    // No of Units (Sales) + Total Units (Sales)

    let formulaValue = this.chartRevenueDetail.homeSold + this.chartRevenueDetail.totalUnits;

    this.UpdateGaugeChart(formulaValue, this.chartRevenueDetail.homeSold);
  }

  private updateHourChart() {
    //Formula
    // (currentIncome +  ( ((avg sales price * avg commission) / 100 ) * totalUnits ) ) / hours

    let commission = (this.chartRevenueDetail.averageSalesPrice * this.chartRevenueDetail.averageCommission) / 100;

    let formula1 = this.chartRevenueDetail.currentIncome + (commission * this.chartRevenueDetail.totalUnits);

    let formulaValue = formula1 == 0 ? 0 : formula1 / this.chartRevenueDetail.hours;

    let initialValue = this.chartRevenueDetail.currentIncome / this.chartRevenueDetail.hours;

    this.UpdateGaugeChart(formulaValue, initialValue);
  }

  private UpdateGaugeChart(formulaValue: number, initialValue: number) {
    let initialEndValue = initialValue * this.multiplicationFactor;

    let finalEndValue = initialEndValue;

    if (formulaValue > initialEndValue) {
      finalEndValue = formulaValue + formulaValue * 0.2;
    }

    finalEndValue = this.roundTo100Or1000(finalEndValue);

    if (['hours1', 'Unit1', 'Volume1', 'Income1'].includes(this.chartType)) {
      this.gaugeChartData.gaugeFirstPart = initialValue;
    } else if (['hours2', 'Unit2', 'Volume2', 'Income2'].includes(this.chartType)) {
      this.gaugeChartData.gaugeFirstPart = formulaValue;
    }

    this.gaugeChartData.gaugeSecondPart = finalEndValue - this.gaugeChartData.gaugeFirstPart;
    this.gaugeChartData.gaugeEndValue = finalEndValue;
    this.gaugeChartData.gaugeStartValue = 0;
  }

  private getRoundOfToThousand(round: number) {
    let number = 0;
    var rest = round % 1000;
    if (rest == 0) return round;

    if (rest > 500) {
      number = round - rest + 1000;
    } else {
      number = round - rest + 500;
    }

    return number;
  }

  private getRoundOfToHundred(round: number) {
    let number = 0;
    var rest = round % 100;

    if (rest == 0) return round;
    if (rest > 50) {
      number = round - rest + 100;
    } else {
      number = round - rest + 50;
    }

    return number;
  }

  private roundTo100Or1000(round: number) {
    if (round > 1000) {
      return this.getRoundOfToThousand(round);
    }

    return this.getRoundOfToHundred(round);
  }
}
