import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { Storage } from 'src/app/Core/storage';
import { accountRoutes } from 'src/app/Core/routes.enum';
import {Router} from '@angular/router';

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {
  constructor(private route: Router) {}
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let headers = new HttpHeaders();

    headers = request.headers.set('Content-Type', 'application/json');

    let authToken = Storage.getToken() ?? '';
    headers = request.headers.set('Authorization', '');
    if (authToken != '') {
      headers = request.headers.set('Authorization', 'bearer ' + authToken);
    }

    const authReq = request.clone({ headers });

    return next.handle(authReq).pipe(
      tap(
        () => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status == 401) {
              this.route.navigateByUrl(
                accountRoutes.account + '/' + accountRoutes.login
              );
              Storage.removeUserData();
            }
            //this.router.navigate(['login']);
          }
        }
      )
    );
  }
}
