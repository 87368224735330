//prod Agent
export const environment = {
  production: false,
  platform: 'DEVELOPMENT',
  baseApiUrl: 'https://theagentprofitplan.com/api/',
  baseUrl: 'https://theagentprofitplan.com/',
  assessmentPdfImgBaseUrl: 'https://theagentprofitplan.com/',
  remoteImageUrl: 'https://theagentprofitplan.com/agent/',
  ProfitPlanImageUrl: 'https://theagentprofitplan.com/',
  courseimages: 'https://theagentprofitplan.com/',
  signalRUrl: 'wss://theagentprofitplan.com/chatHub',
  locationApi: 'https://api.ipregistry.co/?key=d58a1ngrtca7xv8e',
  mobileNoPattern: '000 000 0000',
  mobileNoPrefix: '+1 ',
  calandlyEvent: 'https://calendly.com/timwdavis/mortgageprofitplan',
  isAgent: true,
  adminUri: 'thetimdavis'
};
