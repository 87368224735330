import {HttpClient} from '@angular/common/http';
import {EventEmitter, Injectable} from '@angular/core';
import {HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel} from '@microsoft/signalr';
import {BehaviorSubject, Observable, Subject, map, switchMap, take} from 'rxjs';
import {environment} from 'src/environments/environment';
import {apiRoutes} from '../Core/apiRoutes.enum';
@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  connectionEstablished = new EventEmitter<Boolean>();
  private baseApiUrl = environment.baseApiUrl;
  messageReceived = new EventEmitter<any>();
  updatedOldMessage = new EventEmitter<any>();

  private messagesSubject = new BehaviorSubject<any[]>([]);
  messages$ = this.messagesSubject.asObservable();

  connectionIsEstablished = false;
  private hubConnection!: HubConnection;
  socketUrl = environment.signalRUrl;
  private uploadDataSubject = new Subject<any>();
  uploadData$ = this.uploadDataSubject.asObservable();

  constructor(private http: HttpClient) {
    this.createConnection();
    this.registerOnServerEvents();
    this.startConnection();
  }

  //send messages
  public sendMessage(message: any, clientuniqueid: string, type: string, createdUserName: string, userImage: string) {
    if (this.hubConnection.state === HubConnectionState.Connected) {
      this.hubConnection.invoke('SendMessageToGroup', message, [], 'group1').catch((err) => {
        console.error('Error while initiating WebSocket API call:', err);
      });
    } else {
      console.error('WebSocket connection is not in the Connected state. Cannot send data.');
    }
  }
  //  clientuniqueid, type, createdUserName,
  public editMessage(messageId: string, message: any) {
    if (this.hubConnection.state === HubConnectionState.Connected) {
      this.hubConnection.invoke('ReceiveMessage', {messageId, message}).catch((err) => {
        console.error('Error while initiating WebSocket API call:', err);
      });
    } else {
      console.error('WebSocket connection is not in the Connected state. Cannot send data.');
    }
  }

  private createConnection() {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(this.socketUrl, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
      .configureLogging(LogLevel.Information)
      .build();
  }

  private startConnection(): void {
    this.hubConnection
      .start()
      .then(() => {
        this.connectionIsEstablished = true;
        console.log('Hub connection started');
        this.connectionEstablished.emit(true);
      })
      .catch((err) => {
        console.log('Error while establishing connection, retrying...');
        setTimeout(() => {
          this.startConnection();
        }, 5000);
      });
  }

  private registerOnServerEvents(): void {
    this.hubConnection.on('ReceiveMessage', (data: any) => {
      let messageFiles = data?.messageFiles;
      let message = data?.messsage;
      let type = data?.type;
      let userName = data?.userName;
      let image = data?.profileImageUrl;
      let senderId = data?.fromUserId;
      let id = data?.id;
      let isNew = data?.isNew;
      if (data) {
        this.messageReceived.emit({id, messageFiles, message, type, userName, image, senderId, isNew});
      }
    });
  }

  fetchMessageHistory(userId: string, CategoryId: string) {
    this.http
      .get<any[]>(`${this.baseApiUrl + apiRoutes.ReceiveMessages + '?SubjectId=' + userId + '&CategoryId=' + CategoryId}`)
      .subscribe((messages) => {
        this.messagesSubject.next(messages);
      });
  }
  uploadFile(file: any) {
    return this.http.post(`${this.baseApiUrl + apiRoutes.UploadMessageFiles}`, file);
  }

  joinGroup(messageId: string): void {
    this.hubConnection.invoke('JoinGroup', messageId).catch((err) => console.error(`Error joining group ${messageId}:`, err));
  }

  sendMessageToGroup(messageId: string, message: string): void {
    this.hubConnection
      .invoke('SendMessageToGroup', messageId, message)
      .catch((err) => console.error(`Error sending message to group ${messageId}:`, err));
  }

  updateMessage(id: string, newContent: any): Observable<any> {
    return this.http.put(`${this.baseApiUrl + apiRoutes.EditMessage}`, newContent).pipe(
      switchMap(() => this.messages$),
      take(1),
      map((messages) => {
        const updatedMessages = messages.map((message) => {
          if (message.id === id) {
            return {
              ...message,
              messsage: newContent?.content,
              isEdit: true,
              messageFiles: message?.messageFiles ? message?.messageFiles : []
            };
          } else {
            return message;
          }
        });
        this.messagesSubject.next(updatedMessages);
        this.updatedOldMessage.emit(updatedMessages);
        return updatedMessages;
      })
    );
  }

  public deleteMessage(messageId: string) {
    this.http.delete(`${this.baseApiUrl + apiRoutes.DeleteMessage + '?messageId=' + messageId}`).subscribe(() => {
      const updatedMessages = this.messagesSubject.value.filter((message) => message.id !== messageId);
      this.messagesSubject.next(updatedMessages);
    });
  }
}
