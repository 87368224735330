import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDropdown, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { accountRoutes, appRoute } from 'src/app/Core/routes.enum';
import { Storage } from 'src/app/Core/storage';
import { DashboardPopupComponent } from 'src/app/pages/dashboard/dashboard-popup/dashboard-popup.component';
import { SideBarService } from 'src/app/shared/Services/sideBar.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  logoPath = '/assets/images/menu-logo-mortgage.svg';
  constructor(private router: Router, private sideBarService: SideBarService, public modalService: NgbModal) {
    if (environment.isAgent) {
      this.logoPath = '/assets/images/agent-white-logo-mobile-new.svg';
    }
  }

  autoClose: NgbDropdown['autoClose'] = true;

  public userData: any = null;
  public baseApiUrl: string = environment.baseUrl;
  public remoteImageUrl: string = environment.remoteImageUrl;
  isShowSidebar: boolean = false;

  ngOnInit(): void {
    this.setUserDetails();
    this.sideBarService.isShowSidebar.subscribe({
      next: (data) => {
        this.isShowSidebar = data;
      },
      error: (error) => { }
    });
  }

  private setUserDetails(): void {
    Storage.userDataEvent.subscribe((x) => {
      this.userData = x;
    });
  }

  HeaderSidebarOpen() {
    if (!this.isShowSidebar) {
      this.sideBarService.open();
    } else {
      this.sideBarService.close();
    }
  }

  userHeaderClick(type: string) {
    if (type == 'profile') {
      this.router.navigateByUrl(appRoute.pages + '/' + appRoute.userprofile);
    } else if (type == 'password') {
      this.router.navigateByUrl(appRoute.pages + '/' + appRoute.changepassword);
    } else {
      Storage.removeUserData();
      this.router.navigateByUrl(accountRoutes.account + '/' + accountRoutes.login);
    }
  }

  homePageRedirection() {
    this.router.navigateByUrl(appRoute.pages + '/' + appRoute.dashboard);
  }

  dashboardModal(data: any) {
    const modalRef = DashboardPopupComponent.open(this.modalService);
    modalRef.result
      .then((data: any) => {
        if (data) {
        }
      })
      .catch((res) => { });
  }
}
