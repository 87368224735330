import {AfterViewInit, Directive, ElementRef, Renderer2} from '@angular/core';

@Directive({
  selector: '[appOpenLink]'
})
export class OpenLinkDirective implements AfterViewInit {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.processLinks();
  }

  processLinks(): void {
    let links;
    if (this.el && this.el.nativeElement instanceof Element) {
      links = this.el?.nativeElement?.getElementsByTagName('a');
    }

    for (let i = 0; i < links?.length; i++) {
      const link = links[i];
      const href = link.getAttribute('href');

      if (href && !href.startsWith('http://') && !href.startsWith('https://')) {
        this.renderer.setAttribute(link, 'href', 'https://' + href);
      }

      this.renderer.listen(link, 'click', (event) => {
        event.preventDefault();
        const url = link.getAttribute('href');

        if (url) {
          window.open(url, '_blank');
        }
      });
    }
  }
}
