<section [class]="{'url-agent-wrapper': parentUri}">
  <div class="sign-up-wrapper d-flex flex-wrap align-items-center">
    <div class="sign-up-left w-50 text-center height-sidebar-100">
      <img [src]="logoPath" alt="logo" />

      <!-- 
      <ng-container *ngIf="videoUrl && videoUrl != ''">


        <iframe width="560" height="315" [src]="videoUrl" title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>

      </ng-container> -->

      <div #videoTemplate></div>

      <ng-container *ngIf="!videoUrl || videoUrl == ''">
        <img [src]="imageUrl" alt="img" class="bottom-img" onerror="this.src='/assets/images/signin-bottom.svg'" />
      </ng-container>
    </div>
    <div class="sign-up-right w-50">
      <div class="sign-up-right-wrapper">
        <div class="mobile-logo text-center">
          <img [src]="logoPath" alt="logo" />
        </div>
        <h1 class="main-heading text-center">Sign Up</h1>
        <ng-container *ngIf="parentUri">
          <div class="your-profit-adviser">
            <p>
              <i><img src="/assets/images/officer.svg" /></i>
              Your Profit Advisor:
            </p>
            <label>{{ user.name }}</label>
          </div>
        </ng-container>
        <form [formGroup]="form">
          <div class="form-control-main position-relative">
            <span>
              <img src="/assets/images/user-icon.svg" />
            </span>
            <input formControlName="name" type="text" class="form-control" placeholder="Enter user name" />
            <div
              class="invalid-feedback"
              *ngIf="form.controls['name'].invalid && (form.controls['name'].dirty || form.controls['name'].touched)"
            >
              <div *ngIf="form.controls['name'].errors?.required">Please Enter name.</div>
            </div>
          </div>

          <div class="form-control-main position-relative">
            <span>
              <img src="/assets/images/user-email.svg" />
            </span>
            <input formControlName="email" type="email" class="form-control" placeholder="Enter email" />
            <div
              class="invalid-feedback"
              *ngIf="form.controls['email'].invalid && (form.controls['email'].dirty || form.controls['email'].touched)"
            >
              <div *ngIf="form.controls['email'].errors?.required">Please Enter email.</div>
              <div *ngIf="form.controls['email'].errors?.email">Please Enter valid email.</div>
            </div>
          </div>
          <div class="form-control-main1 position-relative">
            <span>
              <img src="/assets/images/call-icon.svg" />
            </span>
            <ngx-intl-tel-input
              [cssClass]="'custom'"
              [enableAutoCountrySelect]="true"
              [enablePlaceholder]="true"
              [searchCountryFlag]="true"
              [separateDialCode]="separateDialCode"
              [searchCountryField]="[SearchCountryField.All]"
              [preferredCountries]="preferredCountries"
              [selectFirstCountry]="true"
              [selectedCountryISO]="CountryISO.UnitedStates"
              [phoneValidation]="true"
              [numberFormat]="PhoneNumberFormat.National"
              name="mobileNo"
              formControlName="mobileNo"
            >
            </ngx-intl-tel-input>
            <div class="invalid-feedback" *ngIf="form.controls['mobileNo'].invalid && form.controls['mobileNo'].touched">
              <div *ngIf="form.controls['mobileNo'].errors?.required; else errorShow">Please Enter mobile number.</div>
              <ng-template #errorShow>
                <div *ngIf="form.controls['mobileNo'].errors">Please Enter valid mobile number.</div>
              </ng-template>
            </div>
          </div>

          <div class="login-select">
            <div class="form-control-main position-relative">
              <span>
                <img src="/assets/images/globalWhite.svg" class="ImageRemix" />
              </span>
              <input
                formControlName="country"
                id="country"
                placeholder="please enter your Country"
                type="text"
                class="form-control"
                alphaNumeric
              />
              <div
                class="invalid-feedback"
                *ngIf="form.controls['country'].invalid && (form.controls['country'].dirty || form.controls['country'].touched)"
              >
                <div *ngIf="form.controls['country'].errors?.required">Please Select country</div>
              </div>
            </div>
            <div class="form-control-main position-relative">
              <span>
                <img src="/assets/images/StateWhite.svg" class="ImageRemix" />
              </span>
              <select class="form-control" id="state" [formControl]="state" name="state">
                <option value="" selected disabled>
                  {{ form.controls['state'].status === 'DISABLED' ? 'No state available' : 'Select State' }}
                </option>
                <option *ngFor="let state of states" [value]="state">
                  {{ state }}
                </option>
              </select>
              <div
                class="invalid-feedback"
                *ngIf="form.controls['state'].invalid && (form.controls['state'].dirty || form.controls['state'].touched)"
              >
                <div *ngIf="form.controls['state'].errors?.required">Please Select state</div>
              </div>
            </div>
          </div>

          <div class="login-select">
            <div class="form-control-main position-relative">
              <span>
                <img src="/assets/images/cityWhite.svg" class="ImageRemix" />
              </span>
              <input formControlName="city" id="city" placeholder="please enter your city" type="text" class="form-control" alphaNumeric />
              <div
                class="invalid-feedback"
                *ngIf="form.controls['city'].invalid && (form.controls['city'].dirty || form.controls['city'].touched)"
              >
                <div *ngIf="form.controls['city'].errors?.required">Please Enter City</div>
              </div>
            </div>
            <div class="form-control-main position-relative">
              <span>
                <img src="/assets/images/pincodewhite.svg" class="ImageRemix" />
              </span>
              <input formControlName="pinCode" type="number" class="form-control" placeholder="Enter your zipcode" />
              <div
                class="invalid-feedback"
                *ngIf="form.controls['pinCode'].invalid && (form.controls['pinCode'].dirty || form.controls['pinCode'].touched)"
              >
                <div *ngIf="form.controls['pinCode'].errors?.required">Please Enter zipcode</div>
              </div>
            </div>
          </div>
          <div class="form-control-main position-relative">
            <span>
              <img src="/assets/images/location-icon.svg" />
            </span>
            <input formControlName="address" type="text" class="form-control" placeholder="Enter your address" />
            <div
              class="invalid-feedback"
              *ngIf="form.controls['address'].invalid && (form.controls['address'].dirty || form.controls['address'].touched)"
            >
              <div *ngIf="form.controls['address'].errors?.required">Please Enter address.</div>
            </div>
          </div>

          <ng-container *ngIf="isPasswords">
            <div class="form-control-main position-relative">
              <span>
                <img src="/assets/images/eye-fill.svg" />
              </span>
              <input
                formControlName="password"
                type="password"
                class="form-control"
                placeholder="Enter your password"
                minlength="8"
                autocomplete="off"
              />
              <div
                class="invalid-feedback"
                *ngIf="form.controls['password'].invalid && (form.controls['password'].dirty || form.controls['password'].touched)"
              >
                <div *ngIf="form.controls['password'].errors?.required">Please Enter password.</div>
                <div *ngIf="form.controls['password'].errors?.minlength">Please Enter at least 8 character.</div>
              </div>
            </div>
          </ng-container>
          <div class="form-control-main sign-in-btn text-center">
            <button (click)="onSubmit()">Sign Up</button>
          </div>
        </form>
        <p class="text-center">
          Have an account ?
          <a (click)="onLoginClick()" href="javascript:void(0);">Login Here</a>
        </p>
      </div>
    </div>
  </div>
</section>
