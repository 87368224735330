<div class="navigation h-100">
  <div class="navigation-logo text-center" (click)="onLogoClick()">
    <img [src]="logoPath" alt="logo" />
  </div>
  <nav>
    <div class="mobile-close">
      <a href="javascript:void(0);" (click)="HeaderSidebarClose()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32">
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
            fill="rgba(255,255,255,1)"
          />
        </svg>
      </a>
    </div>
    <ul class="list-unstyled">
      <ng-container *ngFor="let data of sideBarList; trackBy: trackById">
        <li>
          <a (click)="HeaderSidebarClose()" href="javascript:void(0);" [routerLink]="[data.link]">
            <img [src]="data.imgPath" />
            <span>{{ data.title }}</span>
          </a>
        </li>
      </ng-container>
    </ul>
  </nav>
</div>
