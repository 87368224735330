import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appNoDot]'
})
export class NoDotDirective {

  constructor(private el: ElementRef) { }

  @HostListener('keydown', ['$event'])
  @HostListener('keyup', ['$event'])
  @HostListener('paste', ['$event'])

  onKeyDown(event: KeyboardEvent) {
    // Check if the dot key (190 for period/dot) is pressed
    let value = this.el.nativeElement.value;
    if (!this.el.nativeElement.value || this.el.nativeElement.value == null || this.el.nativeElement.value == undefined) {
      if (event.keyCode == 110) {
        event?.preventDefault();
      }
    }
    if (event.keyCode == 110 || event.keyCode == 190) {
      event?.preventDefault();
    }
  }

}
